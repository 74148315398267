@import '../../../../styles/variables.scss';

.addWorkspacePeople {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .person {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1px solid lightgray;
    padding: 0.3rem 0;
    align-items: center;
  }
  .button.primary {
    margin-left: auto;
    font-size: 0.9rem;
    padding: 0.25rem 1rem;
    min-height: unset;
    min-width: unset;
    height: 2rem;
    width: 4rem;
  }
  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .userInfoWrapper,
  .userInfoWrapperActive {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
  }

  .userInfoWrapperActive {
    color: #00bb85;
  }

  .userInfoName {
    margin: 0 0 0.1rem 0;
  }

  .userInfoEmail {
    font-weight: 500;
    margin: 0;
    border-top: 0;
  }

  .userTitleField {
    display: flex;
  }

  .checkboxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #fafafa;
    border: 1px solid lightgray;
    overflow: hidden;
  }
}
