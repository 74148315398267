.filePreviewSection {
  .video-js .vjs-big-play-button {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 4rem;
  }
  .video-js.vjs-has-started .vjs-big-play-button {
    display: block;
  }
  .video-js.vjs-playing .vjs-big-play-button {
    display: none;
  }
  .vjs-controls-disabled .vjs-big-play-button {
    display: block;
  }

.video-js.vjs-audio {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: unset;
}


}
