@import '../../styles/variables.scss';

.subnavigation {
  top: 4.3rem;
  right: 0;
  position: absolute;
  width: 15rem;
  background-color: $color-white-primary;
  border-radius: $border-radius-default;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 10px lightgrey;
  z-index: 20;
  max-height: 25rem;
  overflow-y: auto;
  transition: all 0.2s 0.2s linear;
  transition-property: max-height, border, background-color;
  &.hidden {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s linear;
    transition-property: max-height, border, background-color;
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    z-index: 1;
  }
}

.subnavigationItem {
  width: 100%;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $color-grey-primary;
  transition: all 0.2s linear;
  min-height: 4rem;
  display: flex;
  align-items: center;

  &:hover:not(.disabled) {
    background-color: $color-grey-secondary;
    cursor: pointer;
  }
  &.disabled {
    color: grey;
  }
}

@media screen and (max-width: $breakpoint-min) {
  .subnavigation {
    top: 4rem;
    right: 0;
    left: 0;
    margin: auto;
    transition: none;
    width: 90%;
  }
}
