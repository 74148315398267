@import '../../../../../styles/variables.scss';

$color-active: $color-denim-primary;
$color-border: transparentize($color-denim-secondary, 0.5);

.projectChat {
  width: 100%;
  height: 100%;
  border-radius: $border-radius-default;
  

.wrapperTasksOpen {
  width: 100%;
  height: 100%;
  border: 1px solid $color-border;
}

.header {
  height: 3rem;
  display: flex;
}

.headerTab {
  cursor: pointer;
  background-color: #fff;
  width: 50%;
  color: $color-active;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-border;
  svg {
    fill: $color-active;
  }
  h4 {
    margin-left: 0.5rem;
  }

  &:first-child {
    border-top-left-radius: $border-radius-default;
    border-left: 1px solid $color-border;
    border-top: 1px solid $color-border;
  }
  &:last-child {
    border-top-right-radius: $border-radius-default;
    border-top: 1px solid $color-border;
    border-right: 1px solid $color-border;
  }
  &.active {
  color: #fff;
  background-color: $color-active;
  border-color: $color-active;
  svg {
    fill: #fff;
  }
}
}

.contentWrapper {
  height: 32rem;
  background-color: white;
  border-bottom: 1px solid $color-border;
  border-left: 1px solid $color-border;
  border-right: 1px solid $color-border;
  
  overflow-y: auto;
}
.textAreaWrapper {
    flex-basis: 70%;
    height: 100%;
    padding: 0.4rem 0;
}
form {
  background-color: white;
  display: flex;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid $color-border;
  border-left: 1px solid $color-border;
   border-right: 1px solid $color-border;
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
  textarea {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    resize: none;
    border: none;
    outline: none;
    font-size: 0.9rem;
    border-bottom-left-radius: $border-radius-default;
    &::placeholder {
      color: $color-grey-dark;
      font-family: 'Nunito', sans-serif;
    }
  }
  .button {
    width: 95%;
  }
}

.submitSectionWrapper {
  border-left: 1px solid $color-border;
 
  padding: 0.5rem;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}


// div.contentWrapper::-webkit-scrollbar {
//   width: 10px;
// }

// div.contentWrapper::-webkit-scrollbar-track {
//   background: white;
// }

// div.contentWrapper::-webkit-scrollbar-thumb {
//   background-color: #9DBBC0;
//   border-radius: 5px;
//   height: 50px;
// }
.errorWrapper {
  margin: auto;
  width: fit-content;
  text-align: center;
}
@media screen and (max-width: $breakpoint-large) {
  .wrapper {
    flex-flow: column nowrap;
  }
  .chatSection {
    width: 100%;
    max-width: 40rem;
    margin: auto;
  }
}
}
