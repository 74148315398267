.contentWrapper {
  max-width: 700px;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inputWrapper {
  height: 75px;
  border-bottom: none;
  display: grid;
  grid-template-columns: 30% auto;
  padding: 0 0 0 var(--space-2);
}

.inputField, .inputFieldSuccess {
  height: 25px;
  width: 300px;
  margin: auto auto 0 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid lightgray;
  padding-bottom: var(--space-2);
  outline: none;
}

.inputFieldSuccess {
  border-bottom: 1px solid rgba(1, 193, 136, 0.2);
}

.buttonsWrapper {
  display: flex;
  margin-top: var(--space-5);
  width: 400px;
  justify-content: space-between;
}