@import '../../../styles/variables.scss';

.wrapper,
.selectedWrapper {
  margin: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.selectedWrapper {
  border-color: #01c188;
}

.inputWrapper {
  height: 40px;
  border-bottom: none;
}

.inputField {
  height: 40px;
  margin: auto auto 0 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid lightgray;
  outline: none;
}

.renamingTitle {
  color: gray;
}

.emptyProjectWrapper {
  margin: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  border-radius: 7px;
  width: 200px;
  height: 200px;
  position: relative;
  padding-top: 0;
  box-shadow: 2px 2px 7px gray;


}

.emptyProjectLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-decoration: none;
  position: relative;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.gridFiller {
  display: block;
}

.projectSticker {
  background: $color-green-primary;
  position: absolute;
  top: var(--space-5);
  left: -8px;
  width: 80px;
  height: 80px;
  border-top-left-radius: 7px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 25px;
margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: var(--space-2);

  z-index: 2;
}

.emptyProjectSticker {
  background: $color-green-primary;
  left: -8px;
  width: 200px;
  height: 200px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.projectStickerText {
  margin-top: var(--space-1);
  color: white;
  font-weight: 900;
  font-size: 0.7em;
}

.folderPreviewThreeImagesList,
.folderPreviewImageWrapper,
.projectPreviewImagesWrapper {
  display: grid;
  grid-template-areas:
    'first second'
    'third third';
  grid-gap: 2px;
  width: 200px;
  height: 200px;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  border-radius: 7px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.folderPreviewTwoImagesList {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;
  width: 200px;
  height: 200px;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  border-radius: 7px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.folderPreviewImageWrapper,
.projectPreviewImagesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.projectPreviewImagesWrapper img, .folderPreviewImageWrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.folderPreviewThreeImagesListItemWrapperFirst,
.folderPreviewThreeImagesListItemWrapperSecond,
.folderPreviewThreeImagesListItemWrapperThird {
  width: 98px;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.folderPreviewThreeImagesListItemWrapperFirst {
  grid-area: first;
}

.folderPreviewThreeImagesListItemWrapperSecond {
  grid-area: second;
}

.folderPreviewThreeImagesListItemWrapperThird {
  width: 200px;
  grid-area: third;
}

.folderPreviewTwoImagesListItemWrapperFirst,
.folderPreviewTwoImagesListItemWrapperSecond {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 98px;
  width: 200px;
}

.folderPreviewTwoImagesListItemWrapperFirst,
.folderPreviewTwoImagesListItemWrapperSecond > img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  &::before {
    content: "";
    width: 1rem;
  }
  h4 {
    margin: 0;
    text-align: center;
    line-height: 1rem;
  }
}

.starIcon {
  width: 1rem;
  height: 1rem;
  margin: auto 0;
  svg {
    width: 1rem;
    margin: auto;
    fill: $color-green-primary;
  }
}