@import '../../../styles/variables.scss';

.login,
.forgotPassword,
.signup {
  width: 100%;
  display: flex;
  flex-grow: 2;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-flow: column nowrap;
  height: 1000px;
  overflow: auto;
  .card {
    border-radius: $border-radius-default;
    max-width: 500px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-height: 95%;
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.3);
    z-index: 2;
    height: fit-content;
  }
  .header {
    h4,
    h5 {
      text-align: center;
      font-size: 4.357em;
      margin: 0 0 1rem 0;
      font-weight: 900;
      font-family: Nunito;
      color: $color-green-primary;
    }
    h5 {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
    p {
      font-size: 0.9rem;
      text-align: center;
      margin: auto;
      padding: 0 1rem;
      line-height: 0.9rem;
    }
  }
  .contentWrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      width: 100%;
    }
  }

  label,
  input:-webkit-autofill + label {
    // display as placeholder
    font-size: 0.9rem;
    font-weight: normal;
    opacity: 1;
    position: absolute;
    top: 5px;
    left: 1px;
    transition: all 0.15s ease-out;
    color: #000000aa;
  }

  .inputWrapper {
    margin: 1.8rem 0;
    position: relative;
    span {
      position: absolute;
      right: -2.6rem;
      top: 5px;
      font-size: 0.9rem;
      color: $color-grey-dark;
    }
    p {
      color: $color-red-secondary;
      font-size: 0.8rem;
      margin: 0.5rem 0;
    }
    input:not(:placeholder-shown) + label {
      // display as label
      opacity: 1;
      top: -18px;
      font-weight: bold;
      color: #000;
    }
  }

  .buttonWrapper {
    text-align: center;
  }
  button.button {
    padding: 0.5rem 0;
    width: 7.2rem;
    margin: 2rem auto;
  }
  .link {
    color: $color-blue-secondary;
    text-decoration: none;
    transition: color 0.2s linear;
    &:hover {
      color: darken($color-blue-secondary, 20);
    }
  }

  .whiteBackground,
  .greenBackground {
    width: 100%;
    content: '';
    background-color: #fff;
    flex-grow: 2;
  }
  .greenBackground {
    background-color: $color-green-primary;
  }
  .dividerShape {
    width: 100%;
    overflow: hidden;
    position: relative;
    svg {
      fill: $color-green-primary;
      transform: scale(2);
      transform-origin: top center;
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.login > .card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.signup > .card {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  bottom: 0;
}