@import '../../../../styles/variables.scss';

.availableItemWrapper, .unavailableItemWrapper, .selectedItemWrapper {
  height: 50px;
  border-bottom: 1px solid transparentize($color-denim-secondary, 0.5);
  display: flex;
  align-items: center;
}

.unavailableItemWrapper {
  svg path {
    fill: #808080;
  }
}

.availableItemWrapper {
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: transparentize($color-denim-secondary, 0.8);
  }
    svg {
    fill: $color-denim-primary;
  }
}

.unavailableItemWrapper {
  cursor: not-allowed;
}

.selectedItemWrapper {
  background-color: lighten($color-denim-primary, 6);
  color: #fff;
  svg {
    fill: #fff;
  }
}

.availableTitle, .unavailableTitle {
  margin: 0;
  margin-left: 15px;
}

.unavailableTitle {
  color: #808080;
}

.iconWrapper {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.arrowIcon {
  margin: 0;
  margin-left: 15px;
  width: 0.9rem;
  transform: rotate(270deg);
  fill: gray;
}