@import '../styles/variables.scss';

.appModal {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 22;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.closed {
    display: none;
  }
  &.deleteItem .modalContent {
    min-height: unset;
  }
  .header {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    z-index: 5;
    button {
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
      &:hover svg {
        fill: darken($color-denim-secondary, 25);
      }
      svg {
        transition: all 0.2s linear;
        width: 1rem;
        fill: $color-denim-secondary;
        z-index: 10000;
      }
    }
  }
  .modalContent {
    width: 30rem;
    max-width: 95%;
    background-color: #fff;
    border-radius: $border-radius-default;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-flow: column nowrap;
  }
}
