.radioGroup {
  margin-top: 1rem;
}
// Radio-buttons-container
.radioButtonContainer {
  margin-top: 0.5rem;
}

// Radio-buttons
.radioButton {
  width: 100%;
  padding: 0.5rem 0;
  // Actual input-element
  .inputElement {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    &:focus-visible + .box {
      border: 2px solid #45cdaa;
      background-color: lightgray;
    }
    &:disabled {
      color: lightgray;
    }
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover .box > span {
      background-color: transparentize(#45cdaa, 0.6);
    }
  }
  &.disabled {
    color: grey;
    label {
      cursor: default;
    }
    .selected > span {
      background-color: lightgray !important;
    }
  }
}

// Visual radio-circle-thing
.box {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid lightgray;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
  }
}

// Inner circle when selected
.selected > span {
  background-color: #45cdaa !important;
}
// Error states

// .error .inputElement {
//   background-color: $error-light;
//   border-color: $error;
// }

// .errorMessageContainer > p {
//   color: $error;
//   padding-top: 0.25rem;
//   padding-left: 1rem;
// }
