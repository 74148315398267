@import '../../../styles/variables.scss';

.notification {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 1rem;
  border-radius: 2rem;
  width: 18rem;
  position: relative;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);

  .mainContent {
    max-width: 85%;
    font-family: Nunito-Medium, sans-serif;
    font-weight: normal;
    word-break: break-word;
  }
  svg {
    width: 1rem;
    fill: #fff;
    margin-left: 1rem;
  }
  h4 {
    text-transform: uppercase;
    margin: 0;
    font-weight: 800;
  }
  p {
    margin: 0;
  }
  // types
  &.success {
    background-color: transparentize(#46ccab, 0.1);
    svg {
      fill: #1d6d5e;
      stroke: #1d6d5e;
    }
  }
  &.error {
    background-color: transparentize(#dd5271, 0.1);

    svg {
      fill: #7c193f;
      stroke: #7c193f;
      stroke-width: 3px;
      height: 0.8rem;
      width: auto;
    }
  }
  &.info {
    background-color: transparentize(#ffb300, 0.1);

    svg {
      fill: #845800;
      stroke: #845800;
      stroke-width: 3px;
      height: 0.8rem;
      width: auto;
    }
  }
}

@keyframes fadeIn {
  0% {
    margin-left: -150%;
  }
  100% {
    margin-left: 0.25rem;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
