@import '../../../../styles/variables.scss';
@import '../../../../styles/buttons.scss';

.accountSettingsWorkspace {
  margin-bottom: var(--space-10);
  .addItemWrapper {
    width: 180px;
    height: 90px;
    position: fixed;
    bottom: var(--space-7);
    right: var(--space-8);
    display: flex;
    z-index: 3;
  }

  .addWorkspaceButton {
    @include button-icon-round($color-green-primary, scale(1.07));
    margin-left: auto;
  }

  .addWorkspaceButtonDisabled {
    @include button-icon-round($color-grey-primary, none);
    margin-left: auto;
  }
}
