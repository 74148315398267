@import '../../../../../../styles/variables.scss';
.projectMainViewAudioPlayer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-default;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:$color-denim-primary;
    border-radius: $border-radius-default;
    z-index:2;
    svg {
      max-width: 50%;
      max-height: 50%;
      width: 50%;
      fill: #fff;
    


    }
    &.animate {
      svg path {
        animation: move 0s -0.8s linear infinite alternate;
        transform-origin: center center;
        @for $i from 1 through 17 {
          &:nth-child(#{$i}) {
            animation-duration: random(80) + 400 + ms;
          }
        }

      
      }
    }
  }
}

.videoDotList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@keyframes move {
  from {
    //opacity: .5;
    transform: scaleY(0.7);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}