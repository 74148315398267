@import '../../../../styles/variables.scss';

.filePreviewSection {
  flex-basis: 60%;
  overflow: hidden;
  .thumbnail {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 100%;
    &.audio {
      background-color: $color-denim-primary;
      position: relative;
      svg {
        fill: #fff;
        width: 50%;
      }
      &.animate {
        svg path {
          animation: move 0s -0.8s linear infinite alternate;
          transform-origin: center center;
          @for $i from 1 through 17 {
            &:nth-child(#{$i}) {
              animation-duration: random(80) + 400 + ms;
            }
          }
        }
    }
      .audio-wrapper-item-list-file {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
}


@keyframes move {
  from {
    //opacity: .5;
    transform: scaleY(0.7);
  }
  to {
    opacity: 1;
    transform: scaleY(1);
  }
}