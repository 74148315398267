@import '../../../styles/variables.scss';

.modalWrapper {
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
  width: 100%;
}

  .contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.3rem;
  }

.mainHeader {
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
}

.peopleSection, .addPeopleSection {
  width: 100%;
}

.header, .headerActive, .headerDisabled {
  width: 100%;
  height: 35px;
  background-color: #fff;
  color: #233c4d;
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  padding: 0 0 0 var(--space-2);
  border-radius: 7px;
  margin: var(--space-2) 0;
  border: 2px solid #233c4d;
  cursor: pointer;
  transition: all 0.2s linear;
  svg {
    width: 1rem;
    fill: #233c4d;
    transform: rotate(0deg);
    margin-left: 0.5rem;
    transition: all 0.2s linear;
    outline: none;
  }
  h5 {
    margin: 0;
    text-align: left;
    line-height: 0.9rem;
  }
}

.headerActive {
  color: #fff;
  background-color: #233c4d;
    svg {
    width: 1rem;
    fill: #fff;
    transform: rotate(90deg);
    margin-left: 0.5rem;
    transition: all 0.2s linear;
  }
}


.headerDisabled {
  color: #233c4d;;
  background-color: $color-grey-primary;
    svg {
    width: 1rem;
    fill: #fff;
    transform: rotate(90deg);
    margin-left: 0.5rem;
    transition: all 0.2s linear;
  }
}

.headline {
  font-size: 2em;
}

.peopleListWrapper {

}

.loading {
  font-size: 3em;
  font-weight: 900;
  color: gray;
  width: 100px;
}

.loading:after {
  content: ' .';
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%, 12.5% {
      opacity: 0;
  }
  25% {
      opacity: 1;
  }
  37.5% {
      text-shadow: .5em 0;
  }
  50% {
      text-shadow: .5em 0, 1em 0;
  }
  62.5% {
      text-shadow: .5em 0, 1em 0, 1.5em 0;
  }
  75% {
      text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
  }
  87.5%, 100%{
      text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    }
}

