@import '../../../styles/variables.scss';

.toggle-switch {
  margin-right: 10px;
  position: relative;
  width: 3rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  svg {
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: opacity 0.2s 0.2s linear;
    &.checkIcon {
      fill: $color-green-primary;
    }
    &.crossIcon {
      fill: $color-red-primary;
    }
  }

  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $color-denim-secondary;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 1.5rem;
      padding: 0;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: '';
      background-color: $color-green-primary;
      color: #fff;
    }
  }
  &.disabled &-inner {
    background-color: darken($color-grey-primary, 10);
    cursor: not-allowed;
    &:before {
      background-color: darken($color-grey-primary, 10);
      cursor: not-allowed;
    }
    &:after {
      background-color: darken($color-grey-primary, 10);
      cursor: not-allowed;
    }
  }
  &.disabled svg.crossIcon,
  &.disabled svg.checkIcon {
    fill: darken($color-grey-primary, 10);
  }
  &-inner:after {
    content: '';
    padding-right: 10px;
    background-color: $color-red-primary;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    margin: auto;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    border: 0 solid #ccc;
    border-radius: 50%;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0.2rem;
    }
  }
}
