.listItemWrapper {
  background-color: lightgray;
  width: 7.5rem;
  height: 5rem;
  border-radius: 0.5rem;

  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem 0;
}

.list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
}

@media screen and (max-width: 900px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 750px) {
  .list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .list {
    grid-template-columns: 1fr 1fr;
  }
}
