@import '../../styles/variables.scss';

.miniatureFolderSystem {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  
 

  .wrapper, .copyReadyWrapper {
    width: 100%;
    height: 17rem;
    position: relative;
    padding-bottom: 5px;
    border: 1px solid $color-denim-primary;
    border-radius: 0.5rem;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .loader {
      color: $color-denim-secondary;
      opacity: 0.6;
      transform: scale(0.5);
      position: absolute;
      top: 0;
    }
  }
}