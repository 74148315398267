@import '../../../styles/variables.scss';

.userDetails {
  margin: 0;
  display: flex;
  align-items: flex-start;
  position: relative;

  .triggerWrapper {
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    border-radius: 7px;
    transition: all 0.15s ease-in;
    &.active {
      border: 1px solid #01c188;
      box-shadow: 1px 1px 5px #01c188;
    }
  }

  // override styles for custom subnavigation-items
  .subnavigationItem {
    &.userWrapper {
      background-color: $color-grey-secondary;
      cursor: default;
      padding: 0.75rem 0.5rem;
    }
    .profileBall {
      width: 45px;
      height: 45px;
      min-width: 45px;
      min-height: 45px;
    }
    .infoWrapper {
      margin-left: 0.5rem;

      h4 {
        font-size: 0.9rem;
        margin: 0;
        text-transform: capitalize;
        line-height: 1rem;
      }
      h5 {
        margin: 0;
        color: gray;
        font-size: 0.7rem;
        text-overflow: ellipsis;
        max-width: 10rem;
        overflow: hidden;
      }
    }
    &.storageWrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      padding: 0.75rem 0.5rem;
      cursor: default;
      &:hover {
        background-color: #fff;
      }
    }
    .expired-subscription-text {
      color: $color-yellow-primary;
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
    }
    .free-trial-text {
      color: $color-yellow-primary;
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .userLabel {
    margin-left: 0.3rem;
    max-width: 120px;
    line-height: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: normal;
  }

  @media screen and (max-width: $breakpoint-small) {
    .triggerWrapper .userLabel {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-min) {
    position: unset;
    .triggerWrapper {
      min-width: 3rem;
      .profileBall {
        width: 30px;
        height: 30px;
      }
      .profileBall img {
        height: 30px;
        width: 30px;
      }
    }
  }
}
