@import '../../../styles/variables.scss';

.breadCrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  max-width: 70%;
  overflow-x: auto;

  .contentWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: $color-turquoise-primary;
    }
  }

  .link {
    text-decoration: none;
    color: $color-denim-secondary;
    transition: color 0.2s linear;

    h4 {
      margin: 0;
      font-weight: 700;
      font-size: 0.9rem;
    }
    &.resource {
    }
    &.project {
    }
    &.placeholder {
      font-size: 1.5rem;
    }

    &.folder {
    }
    // do not hover passive-element
    &:not(.passive):not(.placeholder):hover {
      color: darken($color-denim-secondary, 15);
    }
    &:last-child {
      color: $color-denim-primary;
    }
    &:last-child:hover {
      color: $color-denim-primary !important;
    }
  }

  .breadCrumbItemText {
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-small) {
    max-width: 100%;
    height: 3rem;
  }
}
