.video-js {
  $bottom: 3.5em;
  $nudge: 5px;
  $middle: 50%;
  $offset-h: -16.5%;
  $offset-v: -15px;

  .vjs-overlay {
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .vjs-overlay-bottom {
    bottom: $bottom;
    left: $middle;
    margin-left: $offset-h;
  }

  svg.line,
  svg.arrow {
    width: 100%;
    height: 100%;
  }
}
