@import '../../../../../../../../../styles/variables.scss';

.message .messageImageSectionTaskItem {
  position: relative;
  width: 100%;
  height: 3.5rem;
  cursor: pointer;
  overflow: hidden;
  border-radius: $border-radius-default;
  background-color: $color-denim-secondary;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center center;
    transition: all 0.2s linear;
    &:hover {
      width: 105%;
      height: 105%;
    }
  }
  .imageTimestampOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    text-align: center;

    h5 {
      margin: auto;
      color: #fff;
    }
  }
  h4 {
    margin: 1rem 0 0 0;
    font-size: 0.8rem;
    text-align: center;
    padding: 0 0.2rem;
    color: #fff;
    line-height: 0.8rem;
  }

  .soundWaveIcon {
    width: 100%;
    height: 100%;
    background-color: $color-denim-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 80%;
      fill: #fff;
      position: static;
      transition: all 0.2s linear;
    }
    &:hover svg {
      width: 85%;
    }
  }
  .alternativeImageSection {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .audioTimestamp {
    color: gray;
    margin: 0 var(--space-2) 0 0;
  }

  .taskImageTimestampOverlay {
    position: absolute;
    top: 0;
    background-color: black;
    opacity: 0.65;
    height: 20px;
    width: 100%;
    text-align: center;
  }

  .taskImageTimestamp {
    margin: 0;
    color: white;
  }

  .iconWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // video-tasks
  .imageTimestampOverlay {
    background-color: transparentize($color-green-primary, 0.6);
  }
  &.lineTask .imageTimestampOverlay {
    background-color: transparentize($color-red-primary, 0.6);
  }
  &.image svg {
    height: 0.65rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    fill: #fff;
  }
  &.image.lineTask svg {
    fill: #fff;
  }
}
