.container {
  padding: 0;
  margin: 0;
  &.hidden {
    display: none;
  }
}

.errorWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
