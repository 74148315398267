.container {
  max-width: 76rem;
  margin: auto;
  p {
    font-size: 1.1rem;
  }
}

.cardsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  position: relative;
}

.ingress {
  margin-bottom: 4rem;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.info {
  margin: auto;
  max-width: 40rem;
  margin-top: 5rem;
  text-align: center;
  padding-bottom: 5rem;
}
