.list {
  list-style-type: none;
  padding: 0;
  height: 15rem;
  width: 100%;
  overflow-y: auto;
}

ul.list::-webkit-scrollbar {
  width: 10px;
}

ul.list::-webkit-scrollbar-track {
  background: white;
}

ul.list::-webkit-scrollbar-thumb {
  background-color: #9DBBC0;
  border-radius: 5px;
  height: 50px;
}