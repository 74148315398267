@import '../../../../../../../../styles/variables.scss';

.taskMessageList {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: scroll;
  //override message-styles to leave more space for main section
  .message {
    padding: 0.5rem 0;
    .messageImageSection {
      flex-basis: 15%;
      padding-right: 1rem;
      .profileBall {
        margin-left: 0;
      }
    }
    .messageMainSection {
      flex-basis: 85%;
    }
    &.reply {
      .messageImageSection {
        padding-right: 1.5rem;
      }
    }
  }
}