@import '../../../../../../styles/variables.scss';
.projectMainViewPdf {
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  img {
    max-height: calc(35rem - 2px); // minus wrapper's borders
    object-fit: scale-down;
    max-width: 100%;
    transition: all 0.2s linear;
    margin: auto;
  }
  .fullScreenIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(100, 99, 99, 0.6);
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    display: flex;
    border-radius: 2px;
    &:hover svg {
      transform: scale(1.05);
    }
    svg {
      transition: all 0.2s linear;
      fill: #fff;
      width: 1rem;
      height: 1rem;
      margin: auto;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: $border-radius-default;
  }
  &.fullScreen {
    width: 100vw;
    height: 100vh;
    max-height: unset;
    max-width: unset;
    position: fixed;
    z-index: 150;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .fullScreenIcon {
      top: 2rem;
      right: 3rem;
      height: 2.5rem;
      width: 2.5rem;
      transform: rotate(90deg);
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
}
