@import '../../../styles/variables.scss';

.searchWrapper {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-shrink: 1;
  padding-right: 0.5rem;

  .searchForm {
      position: relative;
    svg {
      position: absolute;
      left: 0.7rem;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 60%;
      opacity: 0.5;
      path {
        fill: $color-denim-secondary;
        transition: fill 0.2s linear;
      }
    }
    &:hover {
      svg path {
        fill: $color-denim-primary;
      }
    }
  }

  .searchWrapperSearchBar {
    height: 2rem;
    width: 7rem;
    font-size: 0.9rem;
    border-radius: $border-radius-textInput;
    padding: 0.5rem 0.3rem 0.5rem 2.5rem;
    background: rgba(1, 1, 1, 0.03);
    border: 1px solid rgba(1, 1, 1, 0.1);
    outline: none;
    transition: all .5s;
    -webkit-appearance: none;
    &:focus {
      width: 11rem;
      background-color: #fff;
      border-color: #01c188;
      box-shadow: 1px 1px 5px #01c188;
    }
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  .searchWrapperSearchIcon {
    position: absolute;
    margin-left: var(--space-2);
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  .subnavigation {
    top: 3.5rem;
  }
}
@media screen and (max-width: $breakpoint-medium) {
  .searchWrapper {
    justify-content: flex-end;
  }
}

@media screen and (max-width: $breakpoint-min) {
  .searchWrapper {
    height: 3rem;
    position: unset;
    .searchWrapperSearchBar {
      width: 6.2rem;
      font-size: 14px;
      padding: 0.5rem 0.5rem 0.5rem 1.7rem;
      &:focus {
        width: 7rem;
      }
    }

    .searchForm svg {
        position: absolute;
        left: 0.5rem;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 50%;
        opacity: 0.5;
    }
    
   .subnavigation {
    top: 4rem;
  }
  }
}