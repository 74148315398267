.actionablesDropdownTrigger,
.actionablesDropdownTriggerActive {
  position: absolute;
  z-index: 1;
  top: -4px;
  right: -4px;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  font-weight: 900;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  outline: none;
  cursor: pointer;
}

.actionablesDropdownTriggerActive {
  border: 1px solid #01c188;
  box-shadow: 1px 1px 5px #01c188;
}

.actionablesDropdownList {
  list-style-type: none;
  position: absolute;
  border-radius: 5px;
  top: var(--space-5);
  right: 0;
  width: 160px;
  background: white;
  border: 0.5px solid lightgray;
  box-shadow: 2px 2px 1px 2px rgba(1, 1, 1, 0.1);
  color: black;
  font-size: 1.05em;
  font-weight: 400;
  padding: var(--space-2) 0;
  z-index: 5;
  cursor: pointer;
}

.actionablesDropdownListItem {
  margin: 0;
  padding: var(--space-2) 0 var(--space-2) var(--space-6);
}

.actionablesDropdownListItem:hover {
  background: rgba(1, 1, 1, 0.05);
}

.actionablesDropdownListItemLabel {
  margin: 0;
}