@import '../../styles/variables.scss';

.usersList {
  .contributorsWrapper {
    display: flex;

    background: inherit;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }

  .contributorsImg {
    width: 35px;
    height: 35px;
  }

  .profileBall {
    margin-left: -0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .peopleBallList {
    list-style-type: none;
    flex-direction: row-reverse;
    display: flex;
    margin: 0;
    padding: 0;
    &.inactive .profileBall {
      background-color: darken($color-grey-primary, 5) !important;
    }
  }
  .loader {
    color: $color-green-secondary;
  }

  .peopleBallListDots {
    margin: 0;
  }
  .addBall,
  .surplus {
    width: 40px;
    height: 40px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: $color-green-primary;
    transition: background-color 0.2s linear;
    color: #fff;
    margin-left: 0.25rem;
    &:hover {
      background-color: $color-green-primary-dark
    }
    svg {
      width: 0.8rem;
      fill: #fff;
      transform: rotate(45deg);
      stroke-width: 2px;
    }
    &.inactive {
      background-color: darken($color-grey-primary, 5);
      &:hover {
        background-color: darken($color-grey-primary, 5);
      }
    }
  }
  .surplus {
    margin-left: -0.5rem;
    font-weight: bold;
    font-size: 1.1rem;
    background-color: $color-green-primary;
    &:hover {
      background-color: $color-green-primary;
    }
  }
  .notify-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: auto;

    svg {
      height: 100%;
    }
  }
  .confirmModalBackground {
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 120;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .modalContent {
      min-height: unset;
    }
    .header {
      padding: 1rem 0;
      display: flex;
      justify-content: flex-end;
      z-index: 5;
      button {
        border: none;
        outline: none;
        background-color: #fff;
        cursor: pointer;
        &:hover svg {
          fill: darken($color-denim-secondary, 25);
        }
        svg {
          transition: all 0.2s linear;
          width: 1rem;
          fill: $color-denim-secondary;
        }
      }
    }
    .modalContent {
      width: 30rem;
      min-height: 15rem;
      max-width: 95%;
      max-height: 95vh;
      background-color: #fff;
      border-radius: $border-radius-default;
      padding: 0 1rem 1rem 1rem;
      display: flex;
      flex-flow: column nowrap;
      z-index: 180;
    }
  }
}
