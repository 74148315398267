@import '../../styles/variables.scss';

.select {
  position: relative;
  height: 1.5rem;
  cursor: pointer;
  display: flex;

  label {
    color: $color-denim-secondary;
    font-size: 0.9rem;
  }
  .value {
    width: 3.5rem;
    display: flex;
    justify-content: center;
    border-radius: 0.5rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    .arrow-down {
      width: 0.6rem;
      margin-left: 0.25rem;
      fill: $color-denim-secondary;
    }
  }
  .dropdown {
    display: none;
    width: 3.5rem;
    position: absolute;
    top: 1.5rem;
    border: 1px solid black;
    background-color: #fff;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
    box-shadow: 1px 1px 10px lightgrey;
    &.visible {
      display: block;
    }
  }
  .option {
    height: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
