@import '../../../styles/variables.scss';

.updateEmailModal {
  position: relative;
  border-radius: $border-radius-default;
  width: 100%;
  height: 100%;

  h4 {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
    span {
      color: $color-grey-dark;
    }
  }

  .inputWrapper {
    margin: 2rem 0;
    position: relative;
    span {
      position: absolute;
      right: -2.6rem;
      top: 5px;
      font-size: 0.9rem;
      color: $color-grey-dark;
    }
    p {
      color: $color-red-secondary;
      font-size: 0.8rem;
      margin: 0.5rem 0;
    }
  }
  .contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin: 2rem auto;
  }
}
