@import '../../../styles/variables.scss';

.miniatureFolderSystemBreadCrumbs {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  margin: 0;
  width: 100%;
  background-color: $color-denim-primary;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;

h5 {
  margin: 0;
  color: #fff;
  &.clickable {
    cursor: pointer;
  }
  &.unclickable {
    color: #ffffffaa;
  }
}

.arrow {
  margin: 0 10px 0 0;
}


  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #fff;
    }
  }
}