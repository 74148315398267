.list {
  padding: 0;
  list-style-type: none;
  background-color: white;
  border-radius: 5px;
  margin: 0;
  width: 100%;
  max-height: 20rem;
  overflow: auto;
}
