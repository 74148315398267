@import '../../styles/variables.scss';

.notifications {
  position: fixed;
  bottom: 1rem;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  z-index: 200;
}