@import '../../../../../styles/variables.scss';

.workspaceParticipiantsModalPeopleListItem {
  width: 100%;
  display: flex;
  border-bottom: 1px solid lightgray;
  position: relative;
  padding: 0.5rem;
  align-items: center;

.userInfoWrapper {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  padding-left: 0.5rem;
  flex-shrink: 2;
}

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 10%;
  
}

.userInfoName {
  margin: 0;
  span {
    color: $color-grey-dark;
  }
}

.userInfoEmail {
  font-weight: 500;
  margin: 0;
  border-top: 0;
}

.roleSectionWrapper {
  display: flex;
  align-items: center;
  flex-basis: 20%;
  padding-left: 1rem;
}

.selectRoleDropdownWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.selectRoleButton {
  outline: none;
  margin: 0 var(--space-2);
  width: 100px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid lightgray;
  background: rgba(1, 1, 1, 0.03);
  text-align: start;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secondaryText {
  font-weight: 500;
  color: gray;
  margin: 0;
}

.selectRoleText {
  margin: 0 0 0 var(--space-3);
}

.removeButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}

  .button.grey {
    margin-left: auto;
    font-size: 0.9rem;
    padding: unset;
    min-height: unset;
    min-width: unset;
    height: 2rem;
    width: 2rem;
    transition: background-color 0.2s linear;
    svg {
      fill: #000;
      transition: fill 0.2s linear;
    }
    &:hover:not(.disabled):not(.loading) {
      background-color: $color-red-secondary;
      svg {
        fill: #fff;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-min) {
  .workspaceParticipiantsModalPeopleListItem {
  height: unset;
  flex-flow: row wrap;

  .roleSectionWrapper {
    flex-basis: 50%;
  }
  .userInfoWrapper, .userInfoWrapperPending {
    flex-basis: 100%;
    order: 4;
    padding-top: 0.5rem;
  }
}
}