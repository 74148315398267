@import '../../../../styles/variables.scss';

.projectFooter {
  background-color: darken($color-denim-primary, 5);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  .links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    li {
      margin-right: 3px;
      background-color: $color-denim-primary;
      padding: 0 2rem;
      cursor: pointer;
      transition: background-color 0.2s linear;
      &.active {
      svg {
          fill: $color-denim-primary;
        }
        span {
          color: $color-denim-primary;
        }
        &.video {
          background-color: $color-blue-primary;
        }
        &.audio {
          background-color: $color-turquoise-primary;
        }
        &.image {
          background-color: $color-yellow-primary;
        }
        &.doc {
          background-color: $color-pink-primary;
        }
        &.all {
          background-color: $color-grey-dark;
        }
      }
      &:not(.active):hover {
          background-color: lighten($color-denim-primary, 5);
          svg {
            fill: lighten($color-denim-primary, 60);
          }
          span {
            color: lighten($color-denim-primary, 60);
          }
      }
      a {
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }
      svg {
        fill: lighten($color-denim-primary, 40);
        height: 1.5rem;
        margin-right: 1rem;
        transition: fill 0.2s linear;
      }
      span {
        color: lighten($color-denim-primary, 40);
        font-weight: 700;
        margin-right: 0.3rem;
        font-size: 1.1rem;
        white-space: nowrap;
        transition: color 0.2s linear;
      }
      .amount {
        margin-right: 0;
      }
      
    }
  }
  @media screen and (max-width: $breakpoint-large) {
    .links {
      li {
        padding: 0 1rem;
        span {
          font-size: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-medium) {
    .links li {
      span {
        font-size: 0.9rem;
      }
      svg {
        height: 1.4rem;
        margin-right: 0.5rem;
      }
    }  
  }
    @media screen and (max-width: $breakpoint-small) {
      .links li {
        a {
          min-width: 5rem;
        }
        span {
          font-size: 0.9rem;
        }
        .label {
          display: none;
        }
        svg {
          height: 1.5rem;
          margin-right: 0.5rem;
        }
      }  
    }
    @media screen and (max-width: $breakpoint-min) {
      .links li {
        padding: 0 0.5rem;
        a {
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          min-width: 3rem;
        }
       
        span {
          font-size: 0.9rem;
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
        }
        .label {
          display: none;
        }
        svg {
          height: 1.5rem;
          margin: 0 auto;
        }
      }  
    }
}