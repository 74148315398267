@import '../../../styles/variables.scss';

.workspaces {
  display: flex;
  align-items: flex-start;
  position: relative;

  .imageWrapper {
    display: flex;
    justify-content: space-between;
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }
  }

  .unseenNotificationsAmount {
    color: #45cdaa;
    margin: 0;
  }

  .groupTitle {
    width: 100%;
  }

  .iconsWrapper {
    display: flex;
    align-items: center;
  }

  .warningButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  // override styles for custom subnavigation-items
  .subnavigationItem {
    &.workspaceLink {
      h4 {
        font-weight: normal;
        font-size: 1rem;
        margin: 0;
        line-height: 1rem;
      }
      &.active {
        cursor: default;
        position: relative;

        &:hover {
          background-color: inherit;
        }
      }
      .activityIndicator {
        position: absolute;
        left: -5px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 60%;
        background-color: $color-denim-primary;
        border-radius: 9px;
      }
    }
    &.editWorkspace {
      color: $color-green-medium;
      text-decoration: underline;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: inherit;
        color: darken($color-green-medium, 10%);
      }
    }
    &.searchGroups:hover {
      background-color: inherit;
    }

    .imageWrapper {
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      margin-right: 1rem;
      overflow: hidden;
    }
    .expiredButton {
      background-color: transparent;
      border: none;
      padding: none;
      cursor: pointer;
      z-index: 10;

      h5 {
        font-size: 2rem;
        font-weight: 900;
        color: salmon;
        margin: 0;
      }
    }
  }

  .unreadMessagesCounter {
    position: absolute;
    top: 5px;
    left: 30px;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #45cdaa;
    cursor: pointer;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);

    h5 {
      margin: 0;
      font-size: 0.8rem;
      line-height: 0.8rem;
      font-weight: 900;
      color: white;
    }
  }

  .workspaceLabel {
    margin-left: 0.3rem;
    max-width: 120px;
    line-height: 1rem;
    overflow: hidden;
    font-weight: normal;
  }

  .searchField {
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      left: 0.7rem;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 60%;
      opacity: 0.5;
      path {
        fill: $color-denim-secondary;
        transition: fill 0.2s linear;
      }
    }
    &:hover {
      svg path {
        fill: $color-denim-primary;
      }
    }
  }
  .searchInput {
    height: 2rem;
    width: 100%;
    font-size: 0.9rem;
    border-radius: $border-radius-textInput;
    padding: 0.5rem 0.3rem 0.5rem 2.5rem;
    background: rgba(1, 1, 1, 0.03);
    border: 1px solid rgba(1, 1, 1, 0.1);
    outline: none;
    transition: all 0.5s;
    -webkit-appearance: none;
    &:focus {
      background-color: #fff;
      border-color: #01c188;
      box-shadow: 1px 1px 5px #01c188;
    }
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

@media screen and (max-width: $breakpoint-small) {
  .workspaces {
    .workspaceLabel {
      display: none;
    }
  }
}

@media screen and (max-width: $breakpoint-min) {
  .workspaces {
    min-width: 3rem;
    position: unset;
    .imageWrapper {
      height: 30px;
      width: 30px;
    }

    .unreadMessagesCounter {
      top: 3px;
      left: 20px;
    }
  }
}
