@import '../../styles/variables.scss';
 
.textInput {
    width: 100%;
    outline: none;
    height: 1.8rem;
    font-size: 0.9rem;
    &.underline {
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid lightgray;
      outline: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus {
        border: none;
        background-color: none;
        border-bottom: 1px solid lightgray;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      }
    }


  }