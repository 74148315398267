@import '../../../styles/variables.scss';

.roleSelector {
  display: flex;
  align-items: center;
  .toggleRoleButton {
    border: none;
    background-color: unset;
    width: 70px;
    &.editable {
      cursor: pointer;
    }
    .roleTablet {
      margin: 0;
    }
  }
  .submit {
    font-size: 0.8rem;
    border: none;
    color: $color-denim-primary;
    background-color: unset;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s linear;
    &:hover {
      color: darken($color-denim-primary, 10);
    }
  }

  .cta {
    width: 90px;
    position: relative;
  }
  .loader {
    transform: scale(0.3);
    top: -16px;
    color: $color-denim-secondary;
  }
}