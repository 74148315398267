@import '../../../styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10rem auto auto auto;
}

@media screen and (max-width: $breakpoint-small) {
  .wrapper {
    margin: 5rem auto auto auto;
  }
}