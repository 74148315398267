@import '../../../styles/variables.scss';
$max-height: 70vh;
.resourceView {
  max-width: $main-content-max-width;
  margin: auto;
  width: 100%;
  height: $max-height;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  .loader {
    color: $color-green-secondary;
  }
  .toolbar {
    height: 3rem;
    bottom: -3.5rem;
    width: 100%;
    margin: auto;
    position: absolute;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    & > div {
      background-color: $color-denim-primary;
      border-radius: 3rem;
      color: #fff;
    }
    .mainControls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 10rem;
      height: 100%;
      padding: 0 0.2rem;
      .count {
        width: 4.5rem;
        text-align: center;
        font-weight: 600;
      }
    }
    button {
      border: none;
      outline: none;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      position: relative;
      transition: all 0.2s linear;
      cursor: pointer;
      background-color: unset;
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 1.2rem;
        fill: #fff;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .arrow {
      svg {
        transform: rotate(-90deg);
      }
      &:first-child svg {
        transform: rotate(90deg);
      }
    }
    .download,
    .fullscreen {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;
      button {
        margin: 0;
        padding: 0;
      }
    }
    .filename {
      flex-basis: 100%;
      text-align: center;
      margin: 0.5rem 0;
      font-size: 0.9rem;
      color: $color-denim-primary;
    }
  }
  .resource {
    width: 100%;
    height: $max-height;
    position: relative;
    transition: all 0.2s linear;

    &.fullscreen {
      width: 100vw;
      height: 100vh;
      max-height: unset;
      max-width: unset;
      position: fixed;
      z-index: 21;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      .imageWrapper {
        max-height: 100%;
        max-width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .closeIconWrapper {
        position: absolute;
        top: 2rem;
        right: 3rem;
        height: 2.8rem;
        width: 2.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        cursor: pointer;
        svg {
          width: 1.6rem;
          height: 1.6rem;
          fill: #fff;
          transition: all 0.2s linear;
        }
        &:hover svg {
          transform: scale(1.05);
        }
      }
      iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: $border-radius-default;
      }
    }
  }
  .imageWrapper {
    max-height: $max-height;
    display: flex;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: $max-height;
      margin: auto;
    }
  }
  .notRendable {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    svg {
      width: 3rem;
    }
  }

  #video-wrapper-resource,
  #audio-wrapper-resource {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-default;
    display: flex;
    justify-content: center;
    align-items: center;
    &.hidden {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-min) {
    height: 50vh;
    .resource {
      height: 50vh;
      .imageWrapper {
        max-height: 50vh;
        img {
          max-height: 50vh;
        }
      }
      &.fullscreen .closeIconWrapper {
        right: 1rem;
        top: 1rem;
      }
    }
  }

  @media (orientation: landscape) {
    .resource.fullscreen .imageWrapper {
      height: 100%;
    }
  }
  @media (orientation: portrait) {
    .resource.fullscreen .imageWrapper {
      width: 100%;
    }
  }
}
