.checkbox-radio-button-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-radio-button-wrapper:first-child {
  margin-right: 1rem;
}

.checkbox-label-text {
  font-weight: 700;
  color: black;
  margin: 0 0 0 0.5rem;
}
