.error-boundary-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem auto auto auto;

  .headline {
    font-size: 1.7em;
    margin: 0;
  }

  .secondary-headline {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
  }

  .headlines-wrapper {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
