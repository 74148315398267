/* Define css-variables */

/*
 Usage:
  @value variables: "../path/to/styles/variables.css";
  @value color-blue-primary, color-white-primary from variables;

  div {
    background-color: color-white-primary;
    color: color-blue-primary;
  }
*/

/* colors */

$color-green-primary: #45cdaa;
$color-green-primary-dark: #277561;
$color-green-secondary: #57bc72;
$color-green-medium: #1eba92;

$color-turquoise-primary: #00e1a1;

$color-yellow-primary: #ffb300;
$color-yellow-secondary: #fbf85a;

$color-blue-primary: #52c8da;
$color-blue-secondary: #3c8cde;

$color-red-primary: #dc304d;
$color-red-secondary: #e32d4b;

$color-pink-primary: #fa5d89;
$color-pink-secondary: #e383a8;

$color-denim-primary: #233c4d;
$color-denim-secondary: #87949c;

$color-white-primary: #fff;
$color-white-secondary: #fcfcfc;

$color-black-primary: #000;

$color-grey-primary: #dfe1e2;
$color-grey-secondary: #f2f2f2;
$color-grey-medium: #f2f5f4;
$color-grey-dark: #808080;

$color-background-primary: #fff;
$color-background-secondary: #f8fbfa;
$color-background-medium: #f1f4f3;

/* breakpoints */

$breakpoint-large: 1200px;
$breakpoint-medium: 900px;
$breakpoint-small: 750px;
$breakpoint-min: 600px;

/* spacing */

$spacing-fixed-buttons: 3rem;
$spacing-section-horizontal: 1.5rem;
$spacing-section-vertical: 1rem;

/* Border-radius */

$border-radius-button: 1.875rem;
$border-radius-textInput: 1rem;
$border-radius-default: 0.5rem;

/* max-widths */

$main-content-max-width: 1400px;
