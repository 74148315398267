.wrapper {
  margin-top: var(--space-7);
}

.errorWrapper {
  width: 100vh;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}