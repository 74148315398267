@import '../../../styles/variables.scss';

.welcome-screen-wrapper {
  margin: 10rem auto;
  display: flex;
  justify-content: space-between;
  .itemWrapper {
    width: 30rem;
    height: 30rem;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 3rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .headline {
    color: #43D29E;
    font-size: 1.2em;
    margin: 0;
  }
  
  .text {
    color: gray;
  }
  
  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalBackgroundWrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(71, 80, 88, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--modal-background-zindex);
  }
  
  .notificationWrapper {
    position: fixed;
    top: 50px;
    left: calc(50vw - 150px);
    z-index: var(--dialog-box-zindex);
  }
}

@media only screen and (max-width: 1500px) {
  .welcome-screen-wrapper {
    // width: 950px;
    margin: 5rem; 
    .itemWrapper {
      width: 25rem;
      height: 25rem;
    }
  }

}

@media (max-width: 1000px) {
  .welcome-screen-wrapper {
    // width: 700px;
    margin: 3rem;
    display: block;
    
    .itemWrapper {
      margin: 0 auto 40px auto;
    }
  }

}

@media (max-width: 700px) {
  .welcome-screen-wrapper {
    .itemWrapper {
      width: 15rem;
      height: 25rem;
    }
  }
}

.welcomeScreenModal {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0,0,0,0.8);
  z-index: 22;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.closed {
    display: none;
  }
  .header {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    button {
      border: none;
      outline: none;
      background-color: #fff;
      cursor: pointer;
      &:hover svg {
        fill: darken($color-denim-secondary, 25);
      }
      svg {
        transition: all 0.2s linear;
        width: 1rem;
        fill: $color-denim-secondary;
      }
    }
  }
  .modalContent {
    width: 30rem;
    min-height: 35rem;
    max-width: 95%;
    max-height: 95vh;
    background-color: #fff;
    border-radius: $border-radius-default;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-flow: column nowrap;
  }
}

