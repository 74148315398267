@import '../../../../../styles/variables.scss';

.account-settings-workspace-administration-wrapper {
  margin: 1rem 0;

  .header {
    max-width: 45rem;
    height: 2.5rem;
    color: $color-denim-primary;
    display: flex;
    align-items: center;
    padding: 0 0 0 0.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid $color-denim-primary;
    transition: all 0.2s linear;
    svg {
      width: 1rem;
      fill: #233c4d;
      transform: rotate(0deg);
      margin-left: 0.5rem;
      transition: all 0.2s linear;
      outline: none;
    }
  }

  .account-settings-workspace-administration-list-wrapper {
    margin-top: var(--space-5);
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    transition: height 0.2s linear;
  }
  &.open {
    .header {
      background-color: $color-denim-primary;
      color: #fff;
      svg {
        width: 1rem;
        fill: #fff;
        transform: rotate(90deg);
        margin-left: 0.5rem;
        transition: all 0.2s linear;
      }
    }
  }

  @media screen and (max-width: $breakpoint-min) {
    .account-settings-workspace-administration-list-wrapper {
      justify-content: center;
    }
  }
}
