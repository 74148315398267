@import '../../../styles/variables.scss';

.itemListItemFile {
  margin: 1.5rem 2rem;
  display: flex;
  width: 12.5rem;
  height: 12.5rem;
  
  &.project {
    width: 18rem;
    height: 16rem;
  }
  &.folder {
    margin: 1.5rem;
    .toggle-switch {
      display: none;
    }
    .actionablesIconItem {
      margin-left: auto;
    }
  }
  .contentWrapper {
    border-radius: $border-radius-default;
    box-shadow: 1px 1px 7px rgba(1, 1, 1, 0.15);
    border: 1px solid transparent;
    background: white;
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    &.selected {
      border: 1px solid $color-green-primary;
    }
  }
}
