@import '../../../../../styles/variables.scss';

.listItemThumbnail {
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 100%;
  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: auto;
    fill: darken($color-grey-primary, 5);
    &.document {
      fill: $color-pink-primary;
    }
    &.video {
      fill: $color-blue-primary;
    }
    &.audio {
      fill: $color-turquoise-primary;
    }
    &.image {
      fill: $color-yellow-primary;
    }
    &.folder {
      fill: $color-grey-primary;
    }
  }
  .videoWrapper {
    display: none;
  }
  
}