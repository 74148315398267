.numberInput {
  label {
    padding-right: 0.5rem;
  }
  span {
    font-weight: bold;
    font-size: 1.1rem;
    width: 45px;
    display: inline-block;
  }
  button.button {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    padding: 0;
    margin-right: 2px;
    font-size: 1.2rem;
    line-height: 0;
  }
}
