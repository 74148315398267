@import "../../../../../styles/variables.scss";

$primaryTextColor: $color-denim-primary;
$secondaryTextColor: $color-denim-secondary;

.folderViewHeader {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey-primary;
  //padding-bottom: $spacing-section-horizontal;
  height: 40px;


  .sortBySection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
  }

  h5 {
    font-weight: 600;
    color: $secondaryTextColor;
    margin: 0;
    font-size: 0.85rem;
  }

  h4 {
    margin: 0 0 0 0.5rem;
    cursor: pointer;
    transition: all 0.2s linear;
    font-size: 0.85rem;
    color: $primaryTextColor;
    width: 2.5rem;
    text-align: center;
    font-weight: 700;
    &:hover {
      color: darken($primaryTextColor, 15);
    }
  }
  .arrow {
    transition: all 0.2s linear;
    cursor: pointer;
    svg {
      transform: translateY(0);
      fill: $primaryTextColor;
    }
    &:hover svg {
      animation: bounce 1s infinite;
    }
    &.up {
      transform: rotate(0deg);

    }
    &.down {
      transform: rotate(180deg);
    }
  }
  .divider {
    border-left: 1px solid $secondaryTextColor;
    height: 1rem;
    width: 0;
    margin: 0 0.5rem;
  }


  .showTypeSectionButtonText {
    margin: 0;
  }

}

@media screen and (max-width: $breakpoint-min) {
  .folderViewHeader {
  height: unset;
  padding-bottom: 0.5rem;
  flex-flow: column nowrap;
  .sortBySection {
    order: 2;
  }
  }
}


@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  
}