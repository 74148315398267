@import '../../../../../styles/variables.scss';

.soundwave {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #fff;
    width: 40%;
  }
}
