@import '../../../../../../styles/variables.scss';
.projectMainViewVideoPlayer {
  position: relative;
  width: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: $border-radius-default;
  display: flex;
  position: relative;
  .clickArea, .arrow-container {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 2;
    position: absolute;
  }
}
