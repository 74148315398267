@import '../../../../../styles/variables.scss';
.subnavigationItem {
  .icon {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  h4 {
    margin: 0;
    font-weight: normal;
  }
  &.active {
    position: relative;
    .activityIndicator {
      position: absolute;
      left: -5px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 10px;
      height: 60%;
      background-color: $color-denim-primary;
      border-radius: 9px;
    }
  }
  &.exceptionItemWrapper {
    background-color: $color-grey-secondary;
    &:hover {
      cursor: default;
    }
  }
}