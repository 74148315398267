@import '../../../../../../../../styles/variables.scss';

$color-active: $color-denim-primary;
$color-border: transparentize($color-denim-secondary, 0.5);

.contentSection {
  position: relative;
  z-index: 2;
  form {
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
    .textAreaWrapper {
      height: 6rem;
      padding: 0.4rem 0;
    }
    textarea {
      width: 100%;
      height: 6rem;
      display: flex;
      align-items: flex-start;
      padding: 0.5rem;
      resize: none;
      outline: none;
      font-size: 0.9rem;
      background: rgba(1, 1, 1, 0.03);
      border: 1px solid $color-border;
      border-radius: $border-radius-default;
      &::placeholder {
        color: $color-grey-dark;
        font-family: 'Nunito', sans-serif;
      }
    }
    .button {
      width: 6rem;
      margin-left: auto;
    }
  }

  .submitSectionWrapper {
    padding: 1rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
  }
}