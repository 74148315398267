@import '../../../src/styles/variables.scss';

.container {
  max-height: calc(35rem - 2px);
  max-width: 100%;
  position: relative;
}
.thumbnail {
  height: calc(35rem - 2px);
  max-width: 100%;
  object-fit: contain;
  filter: blur(5px);
}
.img {
  max-height: calc(35rem - 2px);
  max-width: 100%;
  object-fit: scale-down;
}
