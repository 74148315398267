.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 250px;
  overflow-y: scroll;
}

ul.list::-webkit-scrollbar {
  width: 10px;
}

ul.list::-webkit-scrollbar-track {
  background: white;
}

ul.list::-webkit-scrollbar-thumb {
  background-color: #9DBBC0;
  border-radius: 5px;
  height: 50px;
}

.exceptionItemWrapper {
  height: 50px;
  padding: var(--space-1) var(--space-3) var(--space-1) var(--space-7);
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
}