.freeTrialEndedView {
  width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-11) auto auto auto;

  .text {
    color: gray;
    font-size: 0.8em;
    font-weight: 400;
  }
}