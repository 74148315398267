@import '../../../../styles/variables.scss';

#video-wrapper-resource {
  .video-js {
    height: 100%;
    max-width: 100%;
    margin: auto;
    background-color: #fff;
    width: 100%;
    position: relative;
    border-radius: $border-radius-default;
    video {
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      outline: none;
    }
    .vjs-tech {
      position: relative;
    }
    .vjs-control-bar {
      position: absolute;
      background-color: $color-denim-primary;
      border-bottom-left-radius: $border-radius-default;
      border-bottom-right-radius: $border-radius-default;
    }
    .vjs-play-progress {
      background-color: #fff;
      border-radius: 0.25rem;
      &:before {
          color: #fff;
          font-size: 1.25rem;
      }
    }
  .vjs-progress-holder {
    height: 0.5rem;
    border-radius: 0.25rem;
  }
  .vjs-load-progress {
    border-radius: 0.25rem !important;
  }

  .vjs-big-play-button {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
  }

  .vjs-tip {
    z-index: 2;
    display: none;
  }

  .vjs-break-overlay {
    z-index: 2;
  }
  &.vjs-fullscreen {
    background-color: #000;
  }
  }
}

#audio-wrapper-resource {
  .video-js.vjs-audio {
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: $color-denim-primary;
    bottom: 0;
    border-radius: $border-radius-default;
    audio {
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      outline: none;
    }
    .vjs-tech {
      position: relative;
    }
    .vjs-control-bar {
      position: absolute;
      background-color: $color-denim-primary;
      border-bottom-left-radius: $border-radius-default;
      border-bottom-right-radius: $border-radius-default;
      z-index: 3;
      width: 100%;
    }
    .vjs-play-progress {
      background-color: #fff;
      border-radius: 0.25rem;
      &:before {
          color: #fff;
          font-size: 1.25rem;
      }
    }
  .vjs-progress-holder {
    height: 0.5rem;
    border-radius: 0.25rem;
  }
  .vjs-load-progress {
    border-radius: 0.25rem !important;
  }

  .vjs-big-play-button {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
  }

  .vjs-tip {
    z-index: 2;
    display: none;
  }

  .vjs-break-overlay {
    z-index: 2;
  }
  &.vjs-fullscreen {
    background-color: #000;
  }
  }
  .video-js.vjs-audio .vjs-big-play-button {
  display: none !important;
}

.video-js.vjs-audio .vjs-control-bar {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.video-js.vjs-audio {
  min-height: 3em !important;
}

.audio-wrapper-resource-view > .vjs-audio {
  position: relative !important;
  width: 800px;
  height: auto !important;
}

}