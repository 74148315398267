.setPictureModal {
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
  width: 100%;


h4 {
  text-align: center;
  font-size: 1.3em;
  margin: 0 0 1rem 0;
}

.contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
