@import '../../../../../../styles/variables.scss';

.showTypeSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  height: 40px;
  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 9rem;
    font-size: 0.85rem;
    cursor: pointer;
    padding: 0.4rem 0.6rem;
    border-radius: 1rem;
    border: 1px solid $color-grey-primary;
    background-color: $color-grey-secondary;
    margin-left: 0.5rem;
    h4 {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 90%;
      text-align: left;
      text-transform: capitalize;
    }
    svg {
      height: 0.7rem;
      transform: rotate(90deg);
      transition: all 0.2s linear;
      fill: $color-denim-secondary;
    }
    &.open {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  //dropdown
  .subnavigation {
    top: 2.5rem;
    width: 9rem;
    font-size: 1rem;
    font-weight: 100;
    overflow: hidden;
    z-index: 4;
    .type {
      width: 100%;
      display: flex;
      padding-top: 0.3rem;
      text-transform: capitalize;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover {
        background-color: $color-grey-secondary;
      }
      &:last-child {
        padding-bottom: 0.3rem;
      }
      &.active {
        color: $color-green-primary;
        font-weight: 700;
        .checkedIcon svg { 
          opacity: 1;
          transition: all 0.2s linear;
        }
      }
    }
    .divider {
      border-top: 1px solid #dfe1e2;
      height: 1px;
      width: 100%;
      margin: 0.3rem 0 0 0;
    }
    .checkedIcon {
      width: 2rem;
      text-align: center;
      svg { 
        opacity: 0; 
         fill: $color-green-primary;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-min) {
  .showTypeSection {
    justify-content: flex-start;
    width: fit-content;
    order: 0;
    .subnavigation {
      left: unset;
      right: 0;
    }
  }
}