.container {
  padding: 2rem;
  border: 0 solid #f1f4f8;
  border-radius: 0.375rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  color: #233c4d;
  width: 24rem;
  h2 {
    font-weight: 900;
    text-transform: capitalize;
  }
  ul {
    padding: 0 0 0 1rem;
    li {
      font-size: 1rem;
      line-height: 1rem;
      padding-bottom: 0.5rem;
    }
  }
  p {
    font-size: 0.8rem;
  }
  button {
    background-color: #ffb300;
    padding: 0.5rem;
    border-radius: 2rem;
    width: 100%;
    cursor: pointer;
    color: white;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 2rem;
    transition: all 0.15s linear;
    &:hover {
      background-color: #ff9900;
    }
  }
}
