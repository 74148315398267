@import '../../../../../../../styles/variables.scss';

$color-border: transparentize($color-denim-secondary, 0.5);

.newTask {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;

  .taskPopUpTextarea {
    width: 100%;
    height: 100px;
    border: 1px solid lightgray;
    margin: var(--space-5) 0 0 0;
    padding: var(--space-2) var(--space-4);
    display: flex;
    justify-content: left;
    align-items: flex-start;
    border-radius: 4px;
    outline: none;
  }

  .taskPopUpTextarea::placeholder {
    margin: 0;
  }

  .closeButton {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
  }

  .dotText {
    margin: 0;
    font-size: 1.5em;
    font-weight: 900;
    color: white;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin: var(--space-2) 0;
  }

  .modal {
    position: absolute;
    z-index: 5;
    background-color: $color-white-primary;
    border-radius: $border-radius-default;
    border: 1px solid lightgray;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    padding: 40px 0 30px 0;
    max-width: 95vw;
    width: 25rem;
    height: fit-content;
    h3 {
      position: absolute;
      margin: 0;
      top: 5px;
      left: 0;
      margin: 0.5rem 1rem;
      font-size: 1.1rem;
      line-height: 1rem;
      color: $color-denim-secondary;
      span {
        color: $color-denim-secondary;
      }
    }
    .modalScrollWrapper {
      max-height: 480px; // use pixels because we position the parent element in js
      overflow-y: auto;
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.6rem;
      cursor: pointer;
      svg {
        fill: $color-grey-dark;
        transition: fill 0.2s linear;
      }
      &:hover svg {
        fill: darken($color-grey-dark, 15);
      }
    }
    &.videoTaskModal {
      background-color: rgba(255,255,255,0.85);
      .contentSection form {
        background-color: transparent;
      }
    }
  }

  .contentSection {
    position: relative;
    z-index: 2;
    form {
      background-color: white;
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      .textAreaWrapper {
        height: 6rem;
        padding: 0.4rem 0;
      }
      textarea {
        width: 100%;
        height: 6rem;
        display: flex;
        align-items: flex-start;
        padding: 0.5rem;
        resize: none;
        outline: none;
        font-size: 0.9rem;
        background: rgba(1, 1, 1, 0.03);
        border: 1px solid $color-border;
        border-radius: $border-radius-default;
        &::placeholder {
          color: $color-grey-dark;
          font-family: 'Nunito', sans-serif;
        }
      }
    }

    .submitSectionWrapper {
      padding: 1rem 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }

    .buttonWrapper {
      display: flex;
      justify-content: center;
    }
  }
  .button {
    width: 6rem;
    margin-left: auto;
  }

  @media screen and (max-width: $breakpoint-medium) {
    .modal {
      position: fixed;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: auto;
      height: fit-content;
      max-height: 90vh;
    }
  }
}
