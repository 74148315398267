@import '../../../../../../styles/variables.scss';
.projectMainViewAudioPlayer {
  .video-js.vjs-audio {
    height: calc(35rem - 2px);
    width: 100%;
    margin: auto;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    border-radius: $border-radius-default;
    audio {
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      outline: none;
    }
    .vjs-tech {
      position: relative;
    }
    .vjs-control-bar {
      position: absolute;
      background-color: $color-denim-primary;
      border-bottom-left-radius: $border-radius-default;
      border-bottom-right-radius: $border-radius-default;
      z-index: 3;
      width: 100%;
    }
    .vjs-play-progress {
      background-color: #fff;
      border-radius: 0.25rem;
      &:before {
          color: #fff;
          font-size: 1.25rem;
      }
    }
  .vjs-progress-holder {
    height: 0.5rem;
    border-radius: 0.25rem;
  }
  .vjs-load-progress {
    border-radius: 0.25rem !important;
  }

  .vjs-marker {
    content: '';
    z-index: 1;
    height: 25px !important;
    width: 25px !important;
    border-radius: 0 50% 50% 50% !important;
    transform: rotate(-135deg);
    background-color: $color-green-primary !important;
    margin-bottom: 10px !important;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: darken($color-green-primary, 15) !important;
    }
  }
  .vjs-big-play-button {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
  }

  .vjs-tip {
    z-index: 2;
    display: none;
  }

  .vjs-break-overlay {
    z-index: 2;
  }
  &.vjs-fullscreen {
    background-color: #000;
  }
  }
}