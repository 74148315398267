@import './variables.scss';

/*
    button-icon-round
*/

@mixin button-icon-round($bg, $hover) {
  bottom: 4rem;
  right: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  z-index: 3;
  border-radius: 100%;
  background-color: $bg;
  cursor: pointer;
  box-shadow: 0px 3px 4px 1px rgba(0,0,0,0.3);
  transition: all 0.3s ease;

  &:hover {
  transform: $hover;
  box-shadow: 0px 3px 4px 3px rgba(0,0,0,0.2);
}
 svg {
  width: 50%;
  fill: #fff;
}
  @media screen and (max-width: $breakpoint-min) {
    width: 3rem;
    height: 3rem;
  }
}


/*
  button-actionable-background
    most commonly used button, can have different states (active, inactive, loading, error)
    children are displayed horizontally
*/
@mixin button-actionable-background {
  padding: 0.5rem 1rem;
  width: fit-content;
  border-radius: $border-radius-button;
  color: $color-white-primary;
  border: none;
  font-weight: 700;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  position: relative;
  display: flex;
  transition: background-color 0.3s ease;
  background-color: $color-blue-primary;

  svg {
    margin-right: 0.2rem;
    height: 1.3rem;
  }

  h5 {
    color: color-white-primary;
    font-size: 0.9rem;
  }
}

@mixin darken-background-on-hover($bg) {
  background-color: $bg;
  &:hover {
    background-color: darken($bg, 15%);
  }
}


