@import '../../../../styles/variables.scss';

.itemInfoSection {
  overflow: hidden;
  padding: 0.3rem 0.5rem;
  width: 100%;
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    margin: 0;
    overflow: hidden;
    word-break: break-all;
    font-size: 0.85rem;
    height: 40px;
  }
  .renamingTitle {
    margin: 0;
    height: 40px;
    color: gray;
  }
  .link {
    text-decoration: none;
    color: $color-blue-secondary;
    transition: color 0.2s linear;
    cursor: pointer;
    &:hover {
      color: darken($color-blue-secondary, 15);
    }
  }
  .detailsRow {
    display: flex;
    color: $color-denim-secondary;
    align-items: center;
    justify-content: space-between;

    h5 {
      margin: 0;
    }
    svg {
      width: 1rem;
      fill: $color-yellow-primary;
    }
  }

  .favouritedItemTitleSection {
    display: grid;
    grid-template-columns: 40px auto;
    overflow: hidden;
    max-width: 250px;
    max-height: 40px;
  }

  .starIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .actionablesIconItem {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.2rem 0;
    svg {
      width: 0.9rem;
      fill: $color-denim-secondary;
      transition: all 0.2s linear;
    }
    &:hover svg {
      fill: $color-green-primary;
    }
  }

  .bottomWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .inputWrapper {
    height: 40px;
    width: 100%;
    border-bottom: none;
    input {
      width: 100%;
      font-size: 0.85rem;
      padding-bottom: 0.2rem;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid $color-denim-secondary;
      outline: none;
    }
  }
}
