@import '../../../../../../../styles/variables.scss';

.dot {
  position: absolute;
  z-index: 3;
  // coordinates will be set in js

  // align element half of its width and height
  margin-top: -20px;
  margin-left: -20px;
  .taskDot {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: $color-green-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: darken($color-green-primary, 15);
    }
  }

  cursor: pointer;
  h4 {
    margin: 0;
    font-size: 1.5em;
    font-weight: 900;
    color: white;
  }

  &.freeLine .taskDot,
  &.freeArrow .taskDot,
  &.straightLine .taskDot,
  &.straightArrow .taskDot {
    background-color: $color-red-secondary;
  }

  @media screen and (max-width: $breakpoint-small) {
    .taskDot {
      width: 30px;
      height: 30px;
    }
    h4 {
      font-size: 1.3rem;
    }
  }
}
