.fileViewWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.expiredWrapper {
  width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-11) auto auto auto;
}