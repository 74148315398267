@import '../../../styles/variables.scss';

.largeGridList {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}

@media screen and (max-width: $breakpoint-large) {
  .largeGridList {
    justify-content: center;
  }
}
