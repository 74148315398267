@import '../../../styles/variables.scss';

.shareFileModal {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  .headline {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
  }

  .section {
    width: 95%;
    margin: auto;
    padding: 1rem;
    border-bottom: 1px solid darken($color-grey-secondary, 10);
    &:last-child {
      border-bottom: none;
    }
    .title {
      margin: 0 0 1rem 0;
    }
  }
  .socialMedia {
    width: 100%;
  }

  .socialMediaLinks {
    width: 100%;
    margin: 0 auto auto 0;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    li {
      margin-right: 2.5%;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover {
        transform: scale(1.1);
      }
      & > div {
        padding: 5px;
        &:focus {
          outline: $color-green-secondary auto 1px;
        }
      }
    }
  }

}
