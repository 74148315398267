@import '../../../styles/variables.scss';

.StorageSpaceLimitModal {
  position: relative;
  width: 100%;
  border-radius: $border-radius-default;
  height: 100%;
  margin: auto;

  h4 {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
  }

  .contentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .closeButton {
    cursor: pointer;
    position: absolute;
    top: var(--space-5);
    right: var(--space-5);
  }

  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}
