@import '../../styles/variables.scss';
.subheader,
.subheaderProject {
  width: 100%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  .wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-section-horizontal;
    width: 100%;
    max-width: $main-content-max-width;
    margin: auto;
  }
  @media screen and (max-width: $breakpoint-medium) {
    .wrapper {
      padding: calc(#{$spacing-section-vertical} / 2) calc(#{$spacing-section-horizontal} / 2);
    }
  }

  .usersList {
    margin-left: auto;
  }

  @media screen and (max-width: $breakpoint-small) {
  }
}

.subheader {
  border-bottom: 1px solid lightgray;
  background-color: white;
}

.subheaderProject {
  border-bottom: 1px solid lightgray;
  background-color: $color-background-secondary;
}
