@import '../../../styles/variables.scss';

.messages {
  margin: 0;
  display: flex;
  align-items: flex-start;
  position: relative;


  .triggerWrapper {

    svg {
      width: 40px;
      height: 40px;
    }
  }

  // override styles for custom subnavigation-items
.subnavigationItem.messageItem {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  & > div:first-child {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 2.5rem;
    white-space: nowrap;
    max-width: 80%;
  }
    h4 { 
      font-size: 1rem;
      margin: 0;
      line-height: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span {
      margin: 0;
      color: gray;
      font-size: 0.8rem;
      text-overflow: ellipsis;
      max-width: 10rem;
      overflow: hidden;
      margin-top: auto;
    }
    .messagesCount {
      font-size: 1rem;
      font-weight: 600;
      &.unread {
        color: $color-green-primary;
        
      }
    }
}

.subnavigationItem.nonClickable {
  background-color: $color-grey-secondary;
  cursor: default;
  border-radius: inherit;
  h4 {
    margin: 0;
    font-weight: 400;
  }
}

  .unreadMessagesCounter {
    position: absolute;
    top: 5px;
    right: 3px;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #45cdaa;
    cursor: pointer;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.3);

    h5 {
      margin: 0;
      font-size: 0.8rem;
      line-height: 0.8rem;
      font-weight: 900;
      color: white;
    }
  }

    @media screen and (max-width: $breakpoint-min) {
      position: unset;
      .triggerWrapper svg {
        width: 30px;
      }
    }

}