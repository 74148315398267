@import '../../../../../styles/variables.scss';

.list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
}

.listItemWrapper {
  font-weight: 900;
  font-size: 0.9em;
  color: #00e1a1;
  background: transparent;
  border: 1px solid lightgray;
  cursor: pointer;
}

.listItemWrapper:hover {
  text-decoration: underline;
}

.showMore {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    height: 5rem;
    width: 7rem;
    cursor: pointer;
    justify-content: center;
    span {
      font-size: 0.9rem;
      font-weight: 900;
      color: lighten($color-denim-primary, 5);
      text-align: center;
      margin: 0 auto;
      padding: 0;
      transition: all 0.2s linear;
      position: relative;
    }
    &:hover {
      span {
        color: $color-denim-primary;
        font-size: 0.95rem;
      }
    }
}
