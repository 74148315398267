@import '../../../../styles/variables.scss';
.wrapper {
  margin-top: var(--space-7);
  width: 100%;
  display: flex;
}

.chatSection {
  width: 30%;
  display: flex;
  border-radius: $border-radius-default;
  margin-left: auto;
}

.modalBackgroundWrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(71, 80, 88, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-background-zindex);
}

.fileUploadingModal {
  position: relative;
  margin: 100px auto;
  background-color: #fafafa;
  border-radius: 10px;
  z-index: var(--modal-box-zindex);
  width: 600px;
  height: 700px;
}

@media screen and (max-width: $breakpoint-large) {
  .wrapper {
    flex-flow: column nowrap;
  }
  .chatSection {
    width: 100%;
    max-width: 40rem;
    margin: auto;
  }
}