@import '../../../styles/variables.scss';

.roleTablet {
  border-radius: 1rem;
  background-color: $color-grey-dark;
  padding: 0.1rem 0.5rem;
  margin: 0 0.5rem;
  color: #fff;
  font-weight: normal;
  font-size: 0.8rem;
  &.admin {
    background-color: $color-yellow-primary;
  }
  &.user {
    background-color: $color-blue-primary;
  }
  &.viewer {
    background-color: lighten($color-grey-dark, 10);
  }
  &.pro {
    background-color: $color-green-medium;
  }
  &.guest {
    background-color: $color-blue-secondary;
  }
}
