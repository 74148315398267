@import '../../../styles/variables.scss';

.button {
  border-radius: $border-radius-button;
  font-size: 1rem;
  transition: all 0.2s linear;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: 500;
  position: relative;
  min-height: 2.3rem;
  min-width: 5rem;
  border: none;
  cursor: pointer;
  &.primary {
    background-color: $color-green-primary;
    color: #fff;
    &:hover:not(.disabled):not(.loading) {
      background-color: darken($color-green-primary, 15);
    }
    &.disabled {
      background-color: darken($color-grey-primary, 25);
    }
  }
  &.secondary {
    background-color: $color-denim-primary;
    color: #fff;
    &:hover:not(.disabled):not(.loading) {
      background-color: lighten($color-denim-primary, 5);
    }
    &.disabled {
      background-color: darken($color-grey-primary, 25);
    }
  }

  &.green {
    background-color: $color-green-secondary;
    color: #fff;
    &:hover:not(.disabled):not(.loading) {
      background-color: darken($color-green-secondary, 10);
    }
    &.disabled {
       background-color: darken($color-grey-primary, 25);
    }
  }

    &.red {
    background-color: $color-red-secondary;
    color: #fff;
    &:hover:not(.disabled):not(.loading) {
      background-color: darken($color-red-secondary, 10);
    }
    &.disabled {
       background-color: darken($color-red-secondary, 25);
    }
  }

  &.denim {
    background-color: $color-denim-primary;
    color: #fff;
    &:hover:not(.disabled):not(.loading) {
      background-color: lighten($color-denim-primary, 10);
    }
    &.disabled {
       background-color: $color-denim-secondary, 25;
    }
  }

  &.grey {
    background-color: $color-grey-primary;
    color: #fff;
    &:hover:not(.disabled):not(.loading) {
      background-color: lighten($color-grey-primary, 10);
    }
    &.disabled {
       background-color: $color-grey-secondary, 25;
    }
  }

  .loader {
    top: -15px;
    transform: scale(0.4);
  }

}

