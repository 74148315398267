@import '../styles/variables.scss';

* {
  box-sizing: border-box;
}
:root {
  font-size: 16px;
}
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;

  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 32px;
  --space-7: 48px;
  --space-8: 64px;
  --space-9: 96px;
  --space-10: 128px;
  --space-11: 192px;
  --space-12: 256px;
  --space-13: 384px;
  --space-14: 512px;
  --space-15: 640px;
  --space-16: 768px;

  --space-minus-1: -4px;
  --space-minus-2: -8px;
  --space-minus-3: -12px;
  --space-minus-4: -16px;
  --space-minus-5: -24px;
  --space-minus-6: -32px;
  --space-minus-7: -48px;
  --space-minus-8: -64px;
  --space-minus-9: -96px;
  --space-minus-10: -128px;
  --space-minus-11: -192px;
  --space-minus-12: -256px;
  --space-minus-13: -384px;
  --space-minus-14: -512px;
  --space-minus-15: -640px;
  --space-minus-16: -768px;
  --modal-background-zindex: 20;
  --modal-box-zindex: 25;
  --dialog-box-zindex: 30;
}

button {
  outline: none;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background-color: #9dbbc0;
  border-radius: 5px;
  height: 50px;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #9dbbc0;
  border-radius: 5px;
  height: 50px;
}

.folderViewWrapper {
  background-color: white;
  margin: 0 auto;
  min-height: 100vh;
}

.projectViewWrapper {
  background-color: #f0f4f3;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
}

.contentWrapper {
  margin: 0 auto 0 auto;
  max-width: $main-content-max-width;
  padding: $spacing-section-vertical $spacing-section-horizontal;
}

// override some styling for unauthenticated views
.authView {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  .contentWrapper {
    max-width: 100%;
    width: 100%;
    padding: 0;
    height: 100%;
    flex-grow: 2;
    display: flex;
    flex-flow: column nowrap;
  }
}

.progressBar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 3px;
  background-color: lightgray;
  z-index: 50;
}

.bar {
  height: 3px;
  background-color: $color-green-primary;
  animation-name: expand;
  animation-duration: 3s;
}

@media screen and (max-width: $breakpoint-medium) {
  .contentWrapper {
    padding: calc(#{$spacing-section-vertical} / 2) calc(#{$spacing-section-horizontal} / 2);
  }
}

@keyframes expand {
  from {
    width: 1%;
  }

  to {
    width: 100%;
  }
}

.notificationWrapper {
  position: fixed;
  top: 20px;
  left: calc(50vw - 70px);
  z-index: var(--dialog-box-zindex);
}
