@import '../../../../../styles/variables.scss';

.projectParticipiantsModalPeopleListItem {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.3rem 0;
  border-bottom: 1px solid lightgray;
  align-items: center;
  .button.grey {
    margin-left: auto;
    font-size: 0.9rem;
    padding: unset;
    min-height: unset;
    min-width: unset;
    height: 2rem;
    width: 2rem;
    transition: background-color 0.2s linear;
    svg {
      fill: #000;
      transition: fill 0.2s linear;
    }
    &:hover:not(.disabled):not(.loading) {
      background-color: $color-red-secondary;
      svg {
        fill: #fff;
      }
    }
  }
  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .userInfoWrapper {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
    max-width: 70%;
    overflow-x: hidden;
  }

  .userTitleField {
    display: flex;
  }

  .userInfoName {
    margin: 0 0 0.1rem 0;
    min-height: 2rem;
    display: flex;
    align-items: center;
  }

  .userInfoEmail {
    font-weight: 500;
    border-top: 0;
    margin: 0;
  }

  .deleteButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
  }
}