@import '../../../styles/variables.scss';

.dropzone {
  margin: 0 auto var(--space-5) auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &:hover {
    .fileSelectWrapper {
      border: 1px dashed $color-green-secondary;
      svg {
        fill: $color-green-secondary;
      }
    }
  }
  &.active .fileSelectWrapper {
    background-color: transparentize($color-green-secondary, 0.7);
    svg {
      width: 3.2rem;
      fill: $color-green-secondary;
    }
  }

  .fileSelectWrapper {
    padding: var(--space-8) var(--space-10);
    border: 1px dashed lighten($color-green-secondary, 15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 8rem;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;
    svg {
      width: 3rem;
      fill: darken($color-grey-primary, 25);
      opacity: 0.6;
      transition: all 0.2s linear;
    }
    input {
      margin: auto;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }
  }

    h5 {
      color: $color-denim-secondary;
      margin: 1rem 0 0 0;
    }

  .text {
    font-weight: 400;
    color: gray;
    margin: var(--space-2) auto 0 auto;
  }

}