.inputField,
.inputFieldSuccess {
  height: 25px;
  width: 300px;
  margin: auto auto 0 0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid lightgray;
  padding-bottom: var(--space-2);
  outline: none;
}

.inputFieldSuccess {
  border-bottom: 1px solid rgba(1, 193, 136, 0.2);
}
