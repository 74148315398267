@import '../../../../styles/variables.scss';

.workspaceParticipiantsModalAddExistingPeopleSection {
  width: 100%;
  .spinnerWrapper {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .loader {
      color: $color-denim-primary;
      transform: scale(0.5);
      top: -20px;
    }
  }

  .errorWrapper {
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .errorText {
    font-weight: 500;
  }
}
