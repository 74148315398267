@import '../../../styles/variables.scss';
@import '../../../styles/buttons.scss';

.wrapper {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filesSelectedText {
  margin: 0;
  color: gray;
}

.list {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  margin: var(--space-2) 0 0 -8px;
  padding: 0;
}

.listItem {
  margin: 0.25rem 0.5rem;
}

.selectAllButton,
.deselectAllButton,
.shareSelectedButton,
.deleteSelectedButton,
.downloadSelectedButton,
.moveSelectedButton,
.copySelectedButton,
.previewSelectedButton {
  @include button-actionable-background;
}

.selectAllButton {
  @include darken-background-on-hover($color-blue-primary);
}

.deselectAllButton {
   @include darken-background-on-hover($color-blue-primary);
}

.shareSelectedButton {
   @include darken-background-on-hover($color-blue-secondary);
}

.deleteSelectedButton {
   @include darken-background-on-hover($color-red-secondary);
}

.downloadSelectedButton {
  @include darken-background-on-hover($color-yellow-primary);
}

.moveSelectedButton {
   @include darken-background-on-hover($color-green-secondary);
}

.copySelectedButton {
  @include darken-background-on-hover($color-green-primary);
}

.previewSelectedButton {
  background-color: orange;
}

.text {
  margin: 0 0 0 var(--space-1);
}

.folderViewButtonWrapper {
  position: absolute;
  top: -48px;
  width: 65px;
  height: 25px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  font-weight: 900;
  background: white;
  z-index: 1;
  border: 1px solid lightgray;
}
