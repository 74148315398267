@import '../../../../styles/variables.scss';

.link,
.linkActive {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  width: 9rem;
  .underline {
    width: 100%;
    height: 10px;
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.linkActive {
  color: $color-denim-primary;
  .underline {
    background-color: $color-denim-primary;
  }
}

.link {
  color: $color-grey-dark;
}

@media screen and (max-width: $breakpoint-small) {
  .link,
  .linkActive {
    text-align: center;
    margin: auto;
    width: 100%;
  }
}
