@import '../../styles/variables.scss';
.spinner {
  width: 50px;
  height: 50px;
  position: relative;

  .spinnerChild {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      border-radius: 100%;
      animation: spinner-circle 1.2s infinite ease-in-out both;
      background-color: $color-green-medium;
    }
  }

  $deg: 0deg;
  $sec: -1.2s;
  @for $i from 1 through 12 {
    $deg: $deg + 30deg;
    $sec: $sec - 0.1s;
    .spinnerCircle#{$i} {
      transform: rotate(#{$deg});
    }

    .spinnerCircle#{$i}:before {
      animation-delay: #{$sec};
    }
  }
}

//animation

@keyframes spinner-circle {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
