@import '../../../../styles/variables.scss';

.setPictureModal_imageSection {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 15rem;
  &.initial {
    border: none;
  }
    @media (max-width: $breakpoint-medium) {
      width: 15rem;
      height: 15rem;
    }

  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 15rem;
    .profileBall {
      height: 15rem;
      width: 15rem;
    }
    .cover {
      border-radius: 7px;
    }
  }
}