@import '../../../../../../../../styles/variables.scss';

.infoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoSectionName {
  color: black;
  margin: 0;
}

.infoSectionSecondary {
  display: flex;
  justify-content: space-between;
}

.infoSectionCreated {
  color: #B9B9B9;
  font-weight: 500;
  margin: 0 var(--space-3) 0 0;
}

.infoSectionActions {
  cursor: pointer;
  color: #B9B9B9;
  font-size: 1.2em;
  margin: var(--space-minus-2) 0 0 0;
}

.messageSection {
  padding: 0.5rem 0 1rem 0;
  border-bottom: 1px solid transparentize($color-denim-secondary, 0.5);
  width: 100%;
  word-wrap: break-word;
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.text {
  font-size: 0.9em;
  margin: 0;
}

.button {
  background: transparent;
  border: none;
  cursor: pointer;
}