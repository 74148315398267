@import '../../../../../styles/variables.scss';

.accountSettingsWorkspaceList {
  margin: 25px 0;

  .header {
    max-width: 45rem;
    height: 2.5rem;
    color: $color-denim-primary;
    display: flex;
    align-items: center;
    padding: 0 0 0 var(--space-2);
    font-size: 1rem;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid $color-denim-primary;
    transition: all 0.2s linear;
    svg {
      width: 1rem;
      fill: #233c4d;
      transform: rotate(0deg);
      margin-left: 0.5rem;
      transition: all 0.2s linear;
      outline: none;
    }
  }

  .list {
    margin-top: var(--space-5);
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    transition: height 0.2s linear;
    height: 0;
  }
  &.open {
    .header {
      background-color: $color-denim-primary;
      color: #fff;
      svg {
        width: 1rem;
        fill: #fff;
        transform: rotate(90deg);
        margin-left: 0.5rem;
        transition: all 0.2s linear;
      }
    }
    .list {
      height: fit-content;
      overflow: visible;
    }
  }
  &.disabled {
    .header {
      background-color: white;
      color: $color-grey-primary;
      border: 2px solid $color-grey-primary;
      svg {
        width: 1rem;
        fill: $color-grey-primary;
        transform: rotate(90deg);
        margin-left: 0.5rem;
        transition: all 0.2s linear;
      }
    }
  }

  @media screen and (max-width: $breakpoint-min) {
    .list {
      justify-content: center;
    }
  }
}
