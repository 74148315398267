@import '../../../../../../styles/variables.scss';

.wrapper,
.wrapperActive,
.wrapperSubGroupActive {
  width: 200px;
  height: 200px;
  background: $color-background-secondary;
  border: 1px solid rgb(207, 206, 206);
  box-shadow: 1px 1px 5px lightgray;
  border-radius: 10px;
  position: relative;
  white-space: wrap;
  margin: 0.5rem;
  transition: border 0.15s ease-in;
}

.wrapper:hover {
  border: 2px solid $color-denim-secondary;
}

.wrapperActive {
  border: 2px solid $color-green-primary;
  background-color: #fff;
}

.clickArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    img,
    svg {
      transform: scale(1.1);
    }
  }
}

.wrapperSubGroupActive {
  background: $color-background-secondary;
}

.workspaceIconWrapper {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  img,
  svg {
    transition: transform 0.2s;
  }
}

.changeButton {
  background-color: lightgray;
}
.title {
  margin-bottom: 0;
  text-align: center;
}

.changeButtonText {
  margin: 0;
}
.actionablesSelect:hover {
  transform: scale(1.05);
}
.actionablesSelect,
.actionablesSelectActive {
  position: absolute;
  z-index: 1;
  top: -4px;
  left: -4px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background: white;
  border: 1px solid $color-denim-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: all 0.2s linear;
}

.actionablesSelectActive {
  background-color: $color-green-primary;
  border-color: $color-green-primary;
}

.actionablesExitGroupButton {
  position: absolute;
  z-index: 1;
  top: -4px;
  right: -4px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background: salmon;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.groupIcon {
  height: 50px;
  width: 50px;
}

.image,
.passiveImage {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
}

.passiveImage {
  opacity: 0.5;
}

.passiveName {
  color: gray;
}

.erroredName {
  color: red;
  margin: 0;
}
