.storageWrapper {
  margin-top: -1rem;
  .workspace-name {
    margin-top: 1rem;
  }
  .workspace-details-wrapper {
    margin-top: 1rem;
    width: 100%;
    .spaceMeter {
      height: 5px;
      width: 100%;
      border-radius: 5px;
      background-color: #dbdbdb;
      .bar {
        height: 5px;
        background-color: #01c188;
        border-radius: 5px;
        transition: width 0.3s linear;
      }
    }
    .title {
      margin: 0 0 0.75rem 0;
    }

    .info {
      display: flex;
      justify-content: space-between;
      span {
        margin: 0.2rem 0 0 0;
        color: gray;
        font-size: 0.7em;
        display: flex;
        align-items: center;
      }
    }

    .accountSettingsWrapper {
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
}
