@import '../../../styles/variables.scss';

.item-actionables-wrapper {
  display: none;
}

@media screen and (max-width: $breakpoint-small) {
  .item-actionables-wrapper {
    display: initial;
  }
}
