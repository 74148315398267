@import '../../../../../../../styles/variables.scss';

.newTask .videoTaskForm {
    .additionalFields {
        display: flex;
        span {
            margin-right: 0.5rem;
            
        }
        label {
            color: $color-denim-secondary;
            font-size: 0.9rem;
        }
        .numberInput {
            margin-left: 1rem;
        }
    }
}