.loadingListItemAvatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingListItemWrapper {
  width: 100%;
  display: flex;
  padding: 5px;
  margin: 10px 0;
}

.loadingListItemAvatar {
  width: 30%;
  height: 40px;
  background: lightgray;
}

.loadingListItemInfoSectionWrapper {
  padding-left: 100px;
  width: 70%;
}

.loadingListItemInfoSectionTitle {
  margin: 5px 0;
  width: 120px;
  height: 20px;
  background: rgb(231, 231, 231);
}

.loadingListItemInfoSectionContent {
  width: 230px;
  height: 30px;
  background: rgb(231, 231, 231);;
}