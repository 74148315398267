@import '../../../../../styles/variables.scss';
.projectMainViewWorkSection {
    margin-right: 1rem;
    flex-grow: 1;
    flex-basis: 70%;
    // displays the whitespace between element and borders
  .mainContent {
    height: 35rem;
    width:100%;
    background-color: #f7fbfa;
    margin-bottom: var(--space-6);
    border: 1px solid transparentize($color-denim-secondary, 0.5);
    display: flex;
    justify-content: center;
    border-radius: $border-radius-default;

  }
  
  .wrapper {
    // takes width and height of the displaying element
    margin: auto;
    &.audio, &.video { 
      width: 100%;
      height: 100%;
    }
  }

  .mainContentWrapperWithoutContent {
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-default;
    flex-basis: 70%;
  }

  .mainContentPreviewItemWrapper {
    
    width:100%;
    background-color: #f7fbfa;
    margin-bottom: var(--space-6);
    border: 1px solid transparentize($color-denim-secondary, 0.5);
    display: flex;
    justify-content: center;
    border-radius: $border-radius-default;
    flex-basis: 70%;
  }

  .mainContentPreviewItemAudioWrapper {
    height: 35rem;
    width:100%;
    margin-bottom: var(--space-6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-default;
    flex-basis: 70%;
  }

  .videoWrapper {
    width:100%;
  }

  .buttonWrapper {
    height: 100%;
    width: 100%;
  }

  .errorWrapper {
    width: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--space-11) auto auto auto;
  }

  .closeButton {
    position: absolute;
    top: var(--space-5);
    right: var(--space-8);
    border: none;
    background: transparent;
    z-index: 2147483638;
    cursor: pointer;
  }

  .documentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .openButton {
    border: none;
    background: transparent;
    cursor: pointer;
  }

    @media screen and (max-width: $breakpoint-large) {
      
      margin-right: 0;
    
  }
}