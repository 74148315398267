@import '../../../styles/variables.scss';

.guestProject {
}
.registerGuest {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 120;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.closed {
    display: none;
  }
  &.deleteItem .modalContent {
    min-height: unset;
  }
  .header {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    z-index: 5;
    button {
      border: none;
      outline: none;
      background-color: #fff;
      cursor: pointer;
      &:hover svg {
        fill: darken($color-denim-secondary, 25);
      }
      svg {
        transition: all 0.2s linear;
        width: 1rem;
        fill: $color-denim-secondary;
      }
    }
  }
  .modal {
    width: 30rem;
    min-height: 20rem;
    max-width: 95%;
    max-height: 95vh;
    background-color: #fff;
    border-radius: $border-radius-default;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-flow: column nowrap;
    z-index: 180;
    button {
      margin-top: 1rem;
    }
  }
  label,
  input:-webkit-autofill + label {
    // display as placeholder
    font-size: 0.9rem;
    font-weight: normal;
    opacity: 1;
    position: absolute;
    top: 5px;
    left: 1px;
    transition: all 0.15s ease-out;
    color: #000000aa;
  }

  .inputWrapper {
    margin: 1.5rem 0;
    position: relative;
    span {
      position: absolute;
      right: -2.6rem;
      top: 5px;
      font-size: 0.9rem;
      color: $color-grey-dark;
    }
    p {
      color: $color-red-secondary;
      font-size: 0.8rem;
      margin: 0.5rem 0;
    }
    input:not(:placeholder-shown) + label {
      // display as label
      opacity: 1;
      top: -18px;
      font-weight: bold;
      color: #000;
    }
  }
}
