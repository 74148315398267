@import '../../../../styles/variables.scss';

.container {
  span:nth-child(2n) {
    margin-left: 0.5rem;
    font-weight: bold;
  }
  a {
    color: $color-blue-secondary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
