.buttonsWrapper {
  display: flex;
  justify-content: center;
  /* margin: var(--space-4) auto;
  width: 350px; */
}

.changePictureButton, .uploadNewButton, .doneButton {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;

  border: none;
  color: gray;
  background-color: lightgray;
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 7px;
}

.fileInputFieldBackgdoundText {
  margin: 0;
}

.uploadNewInputField, .doneInputField, .changePictureInputField {
  height: 60px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  border-radius: 7px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-4) var(--space-4) 0 0;
}

.changePictureButton {
  width: 200px;
}

.changePictureInputField {
  width: 200px;
}

.uploadNewButton {
  width: 200px;
}

.uploadNewInputField {
  width: 200px;
}

.doneButton {
  width: 200px;
  background-color: #00ffb3;
}

.doneInputField {
  width: 200px;
}


@media screen and (max-width: 700px) {
  .wrapper {
    padding: var(--space-1);
  }
}

.groupIcon {
  height: 75px;
  width: 75px;
}