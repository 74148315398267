@import '../../../../../../../styles/variables.scss';

.uploadWrapper {
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 15rem;
}

.contentWrapper {
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s linear;
  svg {
    fill: darken($color-grey-primary, 25);
    width: 3rem;
    transition: all 0.2s linear;
  }
  &:hover {
    opacity: 0.6;
    svg {
      fill: $color-green-secondary;
    }
    h4 {
      color: $color-green-secondary;
    }
  }
  h4 {
    color: darken($color-grey-primary, 25);
    margin-top: 1rem;
    transition: all 0.2s linear;
  }
}
