.addFileWrapper {
  position: fixed;
  bottom: var(--space-10);
  right: var(--space-8);
  display: flex;
  z-index: 3;
}

.addFileContentWrapper {
  width: 90px;
  height: 90px;
  position: relative;
}

.errorWrapper {
  width: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--space-11) auto auto auto;
}
