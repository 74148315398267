@import '../../../../styles/variables.scss';

.workspaceParticipiantsModalPeopleList {
  width: 100%;
  .list {
    padding: 0;
    list-style-type: none;
    background-color: white;
    border-radius: 5px;
    margin: 0;
    width: 100%;
    max-height: 20rem;
    overflow-y: auto;
  }

  .spinnerWrapper {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .loader {
      color: $color-denim-primary;
      transform: scale(0.5);
      top: -20px;
    }
  }

  .errorWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .errorText {
    font-weight: 500;
  }
}