@import '../../../../styles/variables.scss';

.ShareFileModalInputField {
  width: 100%;
  min-height: 3rem;
  position: relative;
  .form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    padding: 0 0.2rem 0 1rem;
    border: 1px solid lightgray;
    border-right: none;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 3rem;
    outline: none;
    font-weight: 400;
    font-size: 0.9rem;
  }

  button {
    border: none;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    width: 7rem;
    font-weight: 700;
    height: 3rem;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    background-color: $color-turquoise-primary;
    cursor: pointer;
    color: #fff;
    transition: all 0.2s linear;
    &:hover:not(:disabled) {
      background-color: darken($color-turquoise-primary, 15);
    }
    &:active, &:focus {
      background-color: lighten($color-turquoise-primary, 15);
    }
    &:disabled {
      cursor: default;
      background-color: $color-denim-secondary;
    }
  }

  .loader {
      color: $color-denim-secondary;
      opacity: 0.6;
      font-size: 6px;
      top: -2rem;
    }


}