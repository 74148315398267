@import '../../../../styles/variables.scss';

.loader {
  top: -15px;
  transform: scale(0.4);
}

.termsWrapper {
  display: flex;
  .text {
    margin: 0 0 0 0.5rem;
  }
  .terms-link {
    color: $color-blue-secondary;
    cursor: pointer;
    margin-left: 0.25rem;
  }
}