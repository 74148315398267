/**
* Global base-styles
*
* Component specific styles are done with css-modules which style on top of
* this and browser's default styling
* https://github.com/css-modules/css-modules
*/

@charset "utf-8";

@import "variables";

@import "typography";

html {
  -webkit-font-smoothing: antialiased;
  background-color: $color-white-primary;
  font-size: 16px;
  color: $color-black-primary;
}

* {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: border-box;
}
