@import '../../../../../../../styles/variables.scss';

.account-settings-workspace-administration-list-item {
  border: 1px solid lightgray;
  padding: 0 1rem;
  height: 3rem;
  width: 45rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-wrapper {
    display: flex;
    width: 20rem;
  }
  .text {
    margin: 0 0 0 0.5rem;
    font-size: 1rem;
    color: lightgray;
  }

  .check-button-wrapper {
    width: unset;
  }

  .actionables-select:hover {
    transform: scale(1.05);
  }

  .info-box-wrapper {
    margin-left: 1rem;
  }

  .actionables-select,
  .actionables-select-active {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3rem;
    background: white;
    border: 2px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .actionables-select-active {
    background-color: $color-green-primary;
    border-color: $color-green-primary;
  }

  .more-info-button {
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 1rem;
    cursor: pointer;
  }

  .expiredButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.account-settings-workspace-administration-list-item-active {
  border: 1px solid $color-denim-primary;
  padding: 0 1rem;
  height: 3rem;
  width: 45rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-wrapper {
    display: flex;
    align-items: center;
    max-width: 20rem;
  }
  .text {
    margin: 0 0 0 0.5rem;
    font-size: 1rem;
  }
  .edit-text {
    margin: 0 0 0 0.5rem;
    color: $color-blue-secondary;
    cursor: pointer;
  }

  .actionables-select:hover {
    transform: scale(1.05);
  }

  .actionables-select,
  .actionables-select-active {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3rem;
    background: white;
    border: 2px solid $color-denim-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .actionables-select-active {
    background-color: $color-green-primary;
    border-color: $color-green-primary;
  }

  .info-box-wrapper {
    margin-left: 1rem;
  }

  .participants-button-wrapper {
    display: block;
  }

  .more-info-button {
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 1rem;
    cursor: pointer;
  }
  .expiredButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    .expiredButtonText {
      font-size: 2rem;
      font-weight: 900;
      color: salmon;
    }
  }
}

@media screen and (max-width: $breakpoint-min) {
  .account-settings-workspace-administration-list-item {
    width: 20rem;
    justify-content: space-between;
    .title-wrapper {
      width: unset;
    }
    .text {
      color: lightgray;
    }

    .check-button-wrapper {
      width: unset;
    }

    .participants-button-wrapper {
      width: unset;
    }
  }

  .account-settings-workspace-administration-list-item-active {
    width: 20rem;
    justify-content: space-between;
    .title-wrapper {
      width: unset;
    }
    .edit-text {
      display: none;
    }

    .check-button-wrapper {
      width: unset;
    }

    .participants-button-wrapper {
      width: unset;
    }
  }
}
