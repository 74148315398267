.loadingWrapper .loading {
  color: gray;
  font-weight: 900;
}

.loadingWrapper .loading:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes dots {
  0%,
  12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    text-shadow: 0.5em 0;
  }
  50% {
    text-shadow: 0.5em 0, 1em 0;
  }
  62.5% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0;
  }
  75% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0;
  }
  87.5%,
  100% {
    text-shadow: 0.5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
  }
}