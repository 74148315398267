@import '../../../styles/variables.scss';

.projectParticipiantsModal {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 150;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  &.closed {
    display: none;
  }
  .header {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    button {
      border: none;
      outline: none;
      background-color: #fff;
      cursor: pointer;
      &:hover svg {
        fill: darken($color-denim-secondary, 25);
      }
      svg {
        transition: all 0.2s linear;
        width: 1rem;
        fill: $color-denim-secondary;
      }
    }
  }
  h4 {
    text-align: center;
    font-size: 1.3em;
    margin: 0 0 1rem 0;
  }
  .subtitle {
    height: 35px;
    background-color: #233c4d;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 0 0 var(--space-2);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  .modalContent {
    width: 30rem;
    min-height: 35rem;
    max-width: 95%;
    max-height: 95vh;
    background-color: #fff;
    border-radius: $border-radius-default;
    padding: 0 1.5rem 2.5rem 1.5rem;
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
  }

  .wrapper {
    position: relative;
    width: 100%;
    border-radius: $border-radius-default;
    height: 100%;

    section {
      margin-top: 1rem;
    }
  }

  .pendingPeople ul {
    list-style-type: none;
    padding: 0;
    li {
      display: flex;
      flex-flow: row nowrap;
      padding: 0.3rem 0;
      border-bottom: 1px solid lightgray;
      align-items: center;
    }
    .email {
      font-size: 0.8em;
    }
    .expired {
      color: $color-red-secondary;
      font-size: 0.8em;
    }
    .button.grey {
      margin-left: auto;
      font-size: 0.9rem;
      padding: unset;
      min-height: unset;
      min-width: unset;
      height: 2rem;
      width: 2rem;
      transition: background-color 0.2s linear;
      svg {
        fill: #000;
        transition: fill 0.2s linear;
      }
      &:hover:not(.disabled):not(.loading) {
        background-color: $color-red-secondary;
        svg {
          fill: #fff;
        }
      }
    }
  }

  .addPeople {
    margin-top: 1rem;
  }

  .mainHeader {
    font-size: 1.3em;
  }

  .peopleSection,
  .addPeopleSection {
    margin: var(--space-5) 0 0 0;
  }

  .headline {
    font-size: 2em;
  }

  .closeButton {
    position: absolute;
    cursor: pointer;
    top: var(--space-3);
    right: var(--space-3);
  }

  .peopleListWrapper {
    height: 300px;
  }

  @media screen and (max-width: 700px) {
    .contentWrapper {
      position: inherit;
      width: 100%;
      padding: var(--space-1);
      display: inherit;
    }

    .closeButton {
      top: var(--space-1);
      right: var(--space-2);
    }
  }

  @media screen and (max-height: 850px) {
    .contentWrapper {
      height: 450px;
      grid-template-rows: 35px 175px 70px 35px;
    }

    .peopleListWrapper {
      height: auto;
    }

    .headline {
      margin: 0;
    }
  }
}
