.mainViewLoadingWrapper {
  margin-top: var(--space-7);
  width: 100%;
  display: grid;
  grid-template-columns: 880px 500px;
}

.mainContentLoadingWrapper {
  margin-right: var(--space-6);
}

.mainContentPreviewItemLoadingWrapper {
  height: 500px;
  max-width: 850px;
  background-color: #F7FBFA;
  margin-bottom: var(--space-6);
  border: 1px solid rgb(191, 211, 214);
  display: flex;
  justify-content: center;
}

.chatLoadingWrapper {
  width: 502px;
  height: 600px;
  border: 1px solid rgb(191, 211, 214);
  border-radius: 5px;
  background-color: #F7FBFA;
}