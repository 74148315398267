@import '../../../../../styles/variables.scss';

.wrapper,
.wrapperActive {
  width: 7.5rem;
  height: 5rem;
  background-color: #f7fbfa;
  border-radius: $border-radius-default;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem 0;

  transition: all 0.2s ease-in-out;
  &.active {
    border: 1px solid #01c188;
    box-shadow: 1px 1px 5px #01c188;
    transform: scale(1.05);
  }
}

.wrapper {
  border: 2px solid transparent;
}

.thumbnailImg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center center;
  transition: all 0.2s linear;
}

.thumbnailImg:hover {
  width: 110%;
  height: 110%;
}

.audioThumbnail {
  width: 100%;
  height: 100%;
  background-color: $color-denim-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #fff;
    width: 70%;
    transition: all 0.2s linear;
  }
  &:hover svg {
    width: 75%;
  }
}

.fallbackThumbnail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: $color-denim-secondary;
    width: 50%;
    transition: all 0.2s linear;
  }
  &:hover svg {
    width: 75%;
  }
}
