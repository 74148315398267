@import '../../../../../styles/variables.scss';

.fileUploadingModalListItem {
  height: 2.5rem;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-bottom: 0.25rem;
  .wrapper {
    margin-left: 0.2rem;
    width: 90%;
    height: 2.5rem;
    background-color: darken($color-grey-primary, 5);
    display: flex;
    overflow: hidden;
    border-radius: 2rem;
    justify-content: space-between;
    position: relative;
    align-items: center;
  }
  .progress {
    content: '';
    position: absolute;
    background-color:  $color-turquoise-primary;
    
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
  }
  &.error {
    .progress {
       background-color:  $color-red-secondary;
       opacity: 0.5;
    }
  }


.filename {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  z-index: 2;
  max-width: 75%;
  span {
    font-size: 0.85rem;
    color: $color-denim-primary;
    font-weight: 600;
    word-break: break-all;
    line-height: 0.9rem;
  }
}
.end {
  display: flex;
  align-items: center;
  z-index: 2;
  h5 {
    margin: 0;
    padding: 0.5rem;
    color: $color-denim-primary;
    font-size: 0.8rem;
    font-weight: 600;
  }
  button {
    border: none;
    outline: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: all 0.2s linear;
    svg {
      width: 1rem;
      fill: lighten($color-red-secondary, 15);
       transition: all 0.2s linear;
    }
    &:hover {
      background-color: lighten($color-red-secondary, 15);
      svg {
        fill: #fff;
      }
    }
}
}

@media screen and (max-width: $breakpoint-min) {
  .end h5 {
      font-size: 0.75rem;
  }
  .filename span {
      font-size: 0.75rem;
  }
}
}

