@import '../../../../styles/variables.scss';

.sendEmailSection {
  width: 100%;
  form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    textarea {
      border-radius: $border-radius-default;
    }
  }
  button.button {
    padding: 0.5rem 0;
    width: 7rem;
    margin: auto 0 auto auto;
  }
  ul {
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0.5rem 0;
      display: flex;
      flex-flow: row wrap;
      height: 4rem;
      overflow-y: auto;
      li {
        height: 1.8rem;
        background-color: ($color-blue-secondary);
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: $border-radius-default;
        overflow: hidden;
        margin-bottom: 0.3rem;
        margin-right: 0.3rem;
        h5 {
          font-size: 0.9rem;
          margin: 0;
          font-weight: 600;
          padding-right: 0.5rem;
          color: #fff;
        }
      }
  }
  .removeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-color: #fff;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    transition: all 0.2s linear;
    position: relative;
    svg {
      position: absolute; 
      width: 7px;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      fill: transparentize($color-red-secondary, 0.3);
      transition: all 0.2s linear;
    }
    &:hover {
      background-color: lighten($color-red-secondary, 15);
      svg {
        fill: #fff;
      }
    }
  }
  .errorText {
    color: transparentize($color-red-secondary, 0.3);
    margin: 0 0 0.5rem 1rem;
    height: 0.7rem;
  }
  textarea {
    width: 100%;
    resize: none;
    border-radius: $border-radius-default;
    padding: 0.25rem 0.5rem;
    border: 1px solid lightgray;
    margin-bottom: 1rem;
    height: 4rem;
    outline: none;
  }
}

