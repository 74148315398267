@import '../../../styles/variables.scss';

.workspaceWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.workspaceTriggerWrapper, .workspaceTriggerWrapperActive {
  margin: auto;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  border: 1px solid transparent;
  padding: 0 0.5rem;
  transition: all 0.2s linear;
  svg {
    height: 2.5rem;
    width: auto;
  }
}

.workspaceLabel {
  margin-left: 0.3rem;
  max-width: 120px;
  line-height: 1rem;
  overflow: hidden;
}

.workspaceTriggerWrapperActive {
  border: 1px solid #01c188;
  border-radius: 7px;
  box-shadow: 1px 1px 5px #01c188;
}

.workspaceDropdownWrapper {
  position: absolute;
  top: 4.3rem;
  right: 0;
  z-index: 5;
}

@media screen and (max-width: $breakpoint-small) {
  .workspaceLabel {
    display: none;
  }
}