.listWrapper {
  position: relative;
  margin-bottom: var(--space-12);
}

.itemsList {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-auto-rows: minmax(250px, auto);
  grid-gap: var(--space-6);
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1600px) {
  .itemsList {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1300px) {
  .itemsList {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1000px) {
  .itemsList {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .itemsList {
    grid-template-columns: 1fr 1fr;
  }
}

.listItemwrapper {
  margin: var(--space-10) auto auto auto;
  display: flex;
  flex-direction: column;
  height: 200px;
}

.thumbnailMock {
  background: lightgray;
  width: 200px;
  height: 100px;
  margin: var(--space-1) 0;
}

.titleMock {
  background: rgb(231, 231, 231);
  width: 150px;
  height: 20px;
  margin: var(--space-1) 0;
}

.infoMock {
  background: rgb(231, 231, 231);
  width: 100px;
  height: 20px;
  margin: var(--space-1) 0;
}