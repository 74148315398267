.list, .overFlowAdjustedList {
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.overFlowAdjustedList {
  overflow-y: scroll;
}