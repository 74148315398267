@import '../../styles/variables.scss';

.navigationBar {
  border-bottom: 1px solid $color-grey-primary;
  background: $color-white-primary;
  display: flex;
  width: 100%;
  padding: $spacing-section-vertical $spacing-section-horizontal;
  & > div {
    margin-left: 1.5rem;
    height: 4rem;
    .triggerWrapper {
      height: 100%;
      width: 100%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      border-radius: 7px;
      transition: all 0.15s ease-in;
      &.active {
        border: 1px solid $color-green-primary;
        box-shadow: 1px 1px 5px $color-green-primary;
      }
      &:not(.active):hover {
        border: 1px solid $color-grey-primary;
        border-radius: 7px;
        box-shadow: 1px 1px 5px $color-grey-primary;
      }
    }
  }

  .authenticatedWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }

  .loginWrapper,
  .signupWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // yemma-icon
  .iconWrapper {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: auto;
    height: 4rem;
    width: 13rem;
    background-image: url('../../assets/yemma-logo-navbar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
  }

  .signOutButton {
    background-color: transparent;
    border: none;
    font-family: 'Nunito';
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
  }

  .link {
    text-decoration: none;
    color: #337ab7;
  }

  .register {
    display: flex;
    align-items: center;
    .link {
      margin-left: 1rem;
      font-weight: 600;
      color: $color-green-medium;
      text-decoration: underline;
      transition: color 0.2s linear;
      &:hover {
        background-color: inherit;
        color: darken($color-green-medium, 10%);
      }
    }
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding: calc(#{$spacing-section-vertical} / 2) calc(#{$spacing-section-horizontal} / 2);
    & > div {
      margin-left: 0.5rem;
    }
  }

  @media screen and (max-width: $breakpoint-min) {
    & > div {
      margin-left: 0.2rem;
      height: 3rem;
    }
    // yemma-icon

    .iconWrapper {
      background-image: url('../../assets/yemma-logo-navbar-mobile.png');
      height: 3rem;
      width: 3rem;
      min-width: 3rem;
    }
  }
}
