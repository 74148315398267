@import '../../../../../../../styles/variables.scss';

.message {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  z-index: 100;
  &:first-child {
    padding-top: 1.5rem;
  }
  &.reply {
 
  }

  .messageImageSection {
    flex-basis: 25%;
    max-width: 6rem;
    height: 100%;
    padding-right: 1rem;
    position: relative;
       svg {
      position: absolute;
      right: 0.5rem;
      fill: $color-denim-primary;
    }
    
    .profileBall {
      width: 3rem;
      height: 3rem;
      margin: auto;
    }
  }

  .messageMainSection {
    flex-basis: 75%;
    width: 75%;
    .infoSection {
      display: flex;
      justify-content: space-between;
      h4 {
        font-size: 0.9rem;
        color: #000;
        margin: 0;
        text-transform: capitalize;
      }
      h5 {
        font-size: 0.75rem;
        color: $color-grey-dark;
        font-weight: normal;
        margin: 0 0 0 auto;
      }
    }
    .removeButton {
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0 0.5rem 0 1rem;
      &:hover:not(.disabled) svg {
        fill: darken($color-grey-dark, 15);
      }
      &.disabled {
          svg {fill: $color-red-secondary;}
        }
      svg {
        width: 0.7rem;
        fill: $color-grey-dark;
        transition: fill 0.2s linear;

      }
    }
  }

}