.workspace-participants-modal-add-new-people-section-content-wrapper {
  width: 100%;
  margin: auto;
  text-align: left;

  .inputField {
    margin: var(--space-2) 0;
    height: 25px;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid lightgray;
    padding-bottom: var(--space-2);
    outline: none;
  }

  .form {
    max-width: 100%;
    margin: var(--space-5) 0 0 0;
  }

  .list {
    text-align: left;
    margin: var(--space-6) 0 var(--space-5) 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0;
  }

  .radioButtonWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 1rem;
  }

  .radioButtonWrapper:last-child {
    margin-right: 0;
  }

  .radioButtonRoleWrapper {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .formSubmitButtonWrapper {
    margin: auto;
    width: 100%;
  }

  .buttonWrapper {
    margin: var(--space-3);
    display: flex;
    justify-content: center;
  }
  .roleSelector {
    
    .toggleRoleButton {
      width: fit-content;
      padding-left: 0;
      span {
        background-color: unset !important;
        color: #000;
        font-size: 1rem;
        padding: 0;
      }
      
    }
    .cta {
      display: none;
    }
  }
}
