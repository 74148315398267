@import '../../../styles/variables.scss';

.checkbox {
  width: 23px;
  height: 23px;
  position: relative;
  display: flex;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 20px;
}

.checkbox label {
  width: 13px;
  height: 13px;
  position: absolute;
  top: var(--space-1);
  left: var(--space-1);
  cursor: pointer;
  background-color: white;
  color: lightgray;
  display: flex;
  align-items: center;
}

.checkbox label:after {
  content: '';
  width: 15px;
  height: 15px;
  position: absolute;
  top: -1px;
  left: -1px;
  background: $color-green-primary;
  border-radius: 15px;
  opacity: 0;
}

.checkbox label:hover::after {
  opacity: 0.3;
}

.checkbox input[type='checkbox'] {
  visibility: hidden;
}

.checkbox input[type='checkbox']:checked + label:after {
  opacity: 1;
}
