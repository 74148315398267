@import '../../../../../../../styles/variables.scss';

$color-border: transparentize($color-denim-secondary, 0.5);

.taskMessage {
  .modal {
    z-index: 4;
    position: absolute;
    background-color: $color-white-primary;
    border-radius: $border-radius-default;
    border: 1px solid lightgray;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    padding: 40px 0 30px 0;
    max-width: 95vw;
    width: 25rem;
    h3 {
      position: absolute;
      margin: 0;
      top: 5px;
      left: 0;
      margin: 0.5rem 1rem;
      font-size: 1.1rem;
      line-height: 1rem;
      color: $color-denim-secondary;
      span {
        color: $color-denim-secondary;
      }
    }
    .durationForm {
      display: flex;
      label {
        color: $color-denim-secondary;
        font-size: 0.9rem;
      }
      .numberInput {
        margin-left: 1rem;
      }
      button.button.denim {
        font-size: 0.8rem;
        padding: 0.25rem 0.5rem;
        min-height: 1rem;
        min-width: 3rem;
        margin-left: 1rem;
      }
    }
    .modalScrollWrapper {
      max-height: 480px; // use pixels because we position the parent element in js
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .closeIcon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.6rem;
      cursor: pointer;
      svg {
        fill: $color-grey-dark;
        transition: fill 0.2s linear;
      }
      &:hover svg {
        fill: darken($color-grey-dark, 15);
      }
    }
  }
  &.video .modal {
    background-color: rgba(255, 255, 255, 0.8);
    ul::-webkit-scrollbar-track {
      background: transparent;
    }
    .contentSection form {
      background-color: transparent;
    }
  }

  @media screen and (max-width: $breakpoint-medium) {
    .modal {
      position: fixed;
      top: 0 !important;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin: auto;
      height: fit-content;
      max-height: 90vh;
    }
  }
}
