@import '../../../styles/variables.scss';
@import '../../../styles/buttons.scss';

.newItemButton {
  @include button-icon-round($color-green-primary, scale(1.07));
  margin-left: auto;
}

.addFileButton {
  @include button-icon-round($color-yellow-primary, scale(1.07));
}

.addItemsWrapper,
.addItemsWrapperProject {
  width: 160px;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.addItemsWrapper {
  bottom: $spacing-fixed-buttons;
  right: $spacing-fixed-buttons;
  position: fixed;
  display: flex;
  justify-content: flex-end;
}

.addItemsWrapperProject {
  bottom: calc(#{$spacing-fixed-buttons} + 2.5rem);
  right: $spacing-fixed-buttons;
}

@media screen and (max-width: $breakpoint-min) {
  .addItemsWrapper,
  .addItemsWrapperProject {
    width: 100px;
    display: initial;
    right: calc(#{$spacing-fixed-buttons} - 2rem);
  }

  .addFileButton {
    margin-left: auto;
    margin-bottom: 0.5rem;
  }
}
