.wrapper {
  padding-bottom: 150px;
}

.modalOpen {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 150;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBoxProjectPeopleList {
  position: relative;
  margin: 100px auto;
  background-color: #fafafa;
  border-radius: 10px;
  z-index: var(--modal-box-zindex);
  width: 500px;
  height: 600px;
}
