@import '../../../../../../styles/variables.scss';
.workspaceParticipiantsModalAddExistingPeopleSectionListItem {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  border-bottom: 1px solid lightgray;

  .userInfoWrapper, .userInfoWrapperPending {
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 50%;
    padding-left: 0.5rem;
    flex-shrink: 2;
  }

  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 10%;
  }

  .userInfoName {
    font-weight: 700;
    margin: 0;
  }

  .userInfoEmail {
    margin: 0;
    font-weight: 500;
    border-top: 0;
  }

  .userTitleField {
    display: flex;
  }

  .roleSelector {
    margin-left: auto;
  }
}
@media screen and (max-width: $breakpoint-min) {
  .workspaceParticipiantsModalAddExistingPeopleSectionListItem {
    height: unset;
    flex-flow: row wrap;

    .roleSectionWrapper {
      flex-basis: 50%;
    }
    .userInfoWrapper, .userInfoWrapperPending {
      flex-basis: 100%;
      order: 4;
      padding-top: 0.5rem;
    }
  }
}